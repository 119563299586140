<template>
  <div class="app" :class="{ 'app--closed': isClosed }">
    <div class="app-sidebar">
      <Sidebar @change-view="checkSidebarView" />
    </div>
    <div class="app-content">
      <div class="app-header">
      	<div class="app-header__logo">
					<router-link :to="{name: 'Employees'}" class="hide-lg">
						<span class="app-header__logo-icon" />
					</router-link>
				</div>
        <div class="app-search">
          <Search />
        </div>
        <div class="app-user">
          <UserCard />
        </div>
      </div>
      <div class="app-page">
        <slot />
      </div>
    </div>
    <AddOptionBg />
    <AddOption />
  </div>
</template>

<script>
import Sidebar from '../components/blocks/Sidebar'
import Search from '../components/blocks/Search'
import UserCard from '../components/blocks/UserCard'
import AddOptionBg from '../components/blocks/AddOptionBg'
import AddOption from '../components/blocks/AddOption'

export default {
  name: 'BaseLayout',
  components: {
    Sidebar,
    Search,
    UserCard,
    AddOptionBg,
    AddOption,
  },
  data() {
    return {
      isClosed: false,
    }
  },
  methods: {
    checkSidebarView(val) {
      this.isClosed = val
    },
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.app {
  display: flex;
  background: #f3f4f5;
  padding-left: 24rem;
  transition: 0.3s ease-out;

  &--closed {
    padding-left: 7.2rem;
  }

  &-content {
    min-height: 100vh;
    padding: 1.6rem 4rem 4rem 3.2rem;
    flex: 1 0;
    max-width: 100%;
	display: flex;
	flex-direction: column;

		@media (max-width: $screen-lg-max){
			padding: 16px;
		}
  }

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 500;
  }

  &-search {
    flex: 1 0 auto;
    padding-right: 10%;
  }

  &-user {
    flex-shrink: 0;
  }

  &-page {
    padding-top: 3.6rem;
	  flex-grow: 1;
	  display: flex;
	  flex-direction: column;
  }
}

@media (max-width: $screen-md-max){
	.app{
		padding-left: 0;
	}
	.app-content {
		padding-bottom: 80px;
	}
	.app-header__logo{
		flex-shrink: 0;
		margin-right: 20px;
	}
	.app-header__logo-icon{
		display: block;
		width: 5.9rem;
		max-width: 100%;
		height: 3rem;
		background-image: url("data:image/svg+xml,%3Csvg width='59' height='30' viewBox='0 0 59 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.0156 18.612L28.8188 13.8089L34.9727 19.8878L54.035 0.750488L58.838 5.55358L34.9727 29.494L24.0156 18.612Z' fill='%23E1AD27'/%3E%3Cpath d='M0 17.8615L4.8031 13.0584L10.9571 19.1373L30.0194 0L34.8224 4.80309L10.9571 28.7435L0 17.8615Z' fill='%231DA4F4'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	.app-search{
		padding-right: 20px;
	}
}

@media (max-width: $screen-sm-max){
	.app-header {
		flex-wrap: wrap;
	}
	.app-search{
		order: 15;
		width: 100%;
		padding-right: 0;
		margin-top: 16px;
	}
}

@media (max-width: $screen-xs-max){
	.app-page{
		padding-top: 32px;
	}
}
</style>
