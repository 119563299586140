import axios from "axios";

const initialState = {
  projectsList: null,
  pagination: null,
  successCreate: false,
  projectData: null,
  projectOffers: null,
  offersPagination: null,
  specialitiesList: null,
  companiesList: null,
  activeVacancy: null,
  vacanciesList: null
};

const state = Object.assign({}, initialState);

const mutations = {
  setData(state, data) {
    state[data.label] = data.data;
  },
  resetState(state) {
    for (let prop in state) {
      state[prop] = initialState[prop];
    }
  }
};

const actions = {
  getProjects({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL +
          `/project?page=${data.page}&pageCount=${data.pageCount}${
            data.sortBy ? "&sort=" + data.sortBy : ""
          }${data.company ? "&company=" + data.company : ""}`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "projectsList",
            data: resp.data.data.data
          });
          commit("setData", {
            label: "pagination",
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page
            }
          });
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  getProject({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/project/${data.id}`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "projectData",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  createProject({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/project",
        data: data.data,
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  editProject({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/project/" + data.id,
        data: data.data,
        method: "PUT"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  deleteProject({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/project/" + id,
        method: "DELETE"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  getOffers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL +
          `/project/${data.id}/vacancies?page=${data.page}&pageCount=${
            data.pageCount
          }
          ${data.sortField ? "&sortField=" + data.sortField : ""}
          ${data.sortBy ? "&sortBy=" + data.sortBy : ""}
          ${data.speciality ? "&speciality=" + data.speciality : ""}
          ${data.payment_type ? "&payment_type=" + data.payment_type : ""}`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "projectOffers",
            data: resp.data.data.data
          });
          commit("setData", {
            label: "offersPagination",
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page
            }
          });
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  addOffer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/vacancy",
        data: data,
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  editOffer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/vacancy/" + data.id,
        data: data.data,
        method: "PUT"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  removeOffer({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/vacancy/" + id,
        method: "DELETE"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  dismissEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL + `/vacancy/${data.id}/delete-employee`,
        data: data.data,
        method: "PUT"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  hireEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/vacancy/${data.id}/add-employee`,
        data: data.data,
        method: "PUT"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  changeStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/project/" + data.id + "/status",
        data: data.data,
        method: "PUT"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  downloadProjectReport({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/project/" + data.id + "/export",
        params: data.data,
        method: "GET",
        responseType: "blob"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  getSpecialities({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/specialities/list",
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "specialitiesList",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  getCompaniesList({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/company/list",
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "companiesList",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  getVacanciesList({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/vacancy/list`,
        method: "GET",
        params: { company: data.id }
      })
        .then(resp => {
          commit("setData", {
            label: "vacanciesList",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  getVacancy({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/vacancy/${data.id}`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "activeVacancy",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
