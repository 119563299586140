<template>
  <div class="new-offer" v-loading="loading">
    <div class="new-offer__name">Добавить специальность</div>
    <el-form class="new-offer__form" @submit.native.prevent="submitForm">
      <div class="new-offer__data">
        <el-checkbox-group v-model="checkedSpecialities">
          <el-checkbox
            v-for="speciality in specialitiesList"
            :label="speciality.id"
            :key="speciality.id"
            class="new-offer__checkbox"
          >
            {{ speciality.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </el-form>
    <div class="new-offer__form__footer">
      <el-button
        type="primary"
        :disabled="isDisabledButton"
        @click="submitForm"
      >
        Сохранить
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetEmployeeSpecialities",
  data() {
    return {
      loading: false,
      isDisabledButton: false,
      checkedSpecialities: []
    };
  },
  computed: {
    employeeSpecialities() {
      return this.$store.state.Employee.employeeSpecialities;
    },
    specialitiesList() {
      return this.$store.state.Employee.specialitiesList;
    }
  },
  created() {
    this.$store.dispatch("Employee/getSpecialities");

    this.$store
      .dispatch("Employee/getEmployeeSpecialities", {
        employee_id: this.$route.params.id
      })
      .then(responses => {
        this.checkedSpecialities = this.employeeSpecialities.map(
          speciality => speciality.id
        );
      });
  },
  methods: {
    submitForm() {
      this.isDisabledButton = true;
      this.loading = true;
      this.$store
        .dispatch("Employee/setEmployeeSpecialities", {
          employee_id: this.$route.params.id,
          specialities: this.checkedSpecialities
        })
        .then(() => {
          this.$store.dispatch("Employee/getEmployeeSpecialities", {
            employee_id: this.$route.params.id
          });
          this.$notify({
            title: "Успех",
            message: "Специальность добавлена",
            type: "success"
          });
          this.$store.commit("Option/closeOption");
          this.isDisabledButton = false;
          this.loading = false;
        })
        .catch(() => {
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.new-offer__form {
  flex-shrink: 1;
  overflow-y: auto;
}

.new-offer__checkbox {
  margin-bottom: 17px;
  width: 100%;
}

@media (max-width: $screen-xs-max) {
  .new-offer {
    padding-top: 26px;
  }
  .new-offer__name {
    padding-left: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 26px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e3e5ec;
  }
  .new-offer__data {
    max-width: none;
    padding: 0 16px;
    overflow: visible;
    width: 100%;
  }
  .new-offer__form__footer {
    padding: 16px 16px 32px;
    border: none;

    .el-button {
      width: 100%;
    }
  }
  .new-offer__checkbox {
    margin-right: 0;
  }
}

@media (orientation: landscape) {
  .new-offer {
    padding-top: 16px;
  }
  .new-offer__name {
    padding-bottom: 16px;
  }
}
</style>
