<template>
  <transition name="slide">
    <div v-if="currentOption" class="option">
      <div class="option__close" @click="closeOption">
        <img src="@/assets/icons/icon-close.svg">
      </div>
      <component :is="currentOption" />
    </div>
  </transition>
</template>

<script>
import CompanyCard from './CompanyCard'
import ProfessionCard from './ProfessionCard'
import AddOffer from './AddOffer'
import SetEmployeeSpecialities from './SetEmployeeSpecialities'
import Chat from './Chat'

export default {
  name: 'AddOption',
  components: {
    CompanyCard,
    ProfessionCard,
    AddOffer,
	  SetEmployeeSpecialities,
    Chat,
  },
  computed: {
    currentOption() {
      return this.$store.state.Option.currentOption
    },
  },
	watch: {
		currentOption(newValue, oldValue) {
			if (newValue) {
				document.body.style.overflow = 'hidden'
			} else {
				document.body.style.overflow = 'auto'
			}
		}
	},
  mounted() {
    document.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.closeOption()
      }
    })
  },
  methods: {
    closeOption() {
      this.$store.commit('Option/setData', {
        label: 'currentOption',
        data: null,
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.option {
  position: fixed;
  right: 0;
  top: 0;
  height: calc(100% - env(safe-area-inset-bottom));
  z-index: 600;
  background: $basicWhite;
  width: 780px;
  max-width: 100%;

  &__close {
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    right: 4rem;
    top: 4rem;
    cursor: pointer;
  }
}

@media (max-width: $screen-sm-max){
	.option__close{
		right: 20px;
		top: 20px;
	}
}
</style>
