var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "el-fade-in"
    }
  }, [_vm.currentOption ? _c('div', {
    staticClass: "option-bg",
    on: {
      "click": _vm.closeOption
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }