<template>
  <div class="new-offer" v-loading="loading">
    <div v-if="projectName" class="new-offer__name">
      {{
        currentOffer
          ? "Редактирование вакансии в проекте"
          : "Новая вакансия в проекте"
      }}
      <br />
      {{ projectName }}
    </div>
    <el-form
      class="new-offer__form"
      ref="form"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
      @submit.native.prevent="submitForm"
    >
      <div class="new-offer__data">
        <el-form-item label="Специальность" prop="speciality_id">
          <el-select
            v-model="form.speciality_id"
            filterable
            :disabled="isDisabled"
            placeholder="Все специальности"
          >
            <el-option
              v-for="(item, index) in specialitiesList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="new-offer__data-payment">
          <el-form-item label="Оплата (баллов)" prop="payment">
            <el-input v-model="form.payment" type="text" />
          </el-form-item>
          <el-form-item prop="resource">
            <el-radio-group v-model="resource">
              <el-radio label="Почасовая" :value="'pay_by_hours'" />
              <el-radio label="Сдельная" :value="'piece_work_payment'" />
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item
          label="График работы исходя из вакансии"
          prop="vacancy_id"
        >
          <el-select
            @change="changeSpeciality"
            v-model="form.vacancy_id"
            filterable
            :disabled="isDisabled"
            placeholder="Выберите график работы исходя из вакансии"
          >
            <el-option
              v-for="(item, index) in vacanciesList"
              :key="index"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="График на неделю"
          prop="workweek"
          class="new-offer__days"
        >
          <el-checkbox-group v-model="workweek">
            <el-checkbox label="ПН" name="workweek" />
            <el-checkbox label="ВТ" name="workweek" />
            <el-checkbox label="СР" name="workweek" />
            <el-checkbox label="ЧТ" name="workweek" />
            <el-checkbox label="ПТ" name="workweek" />
            <el-checkbox label="СБ" name="workweek" />
            <el-checkbox label="ВС" name="workweek" />
          </el-checkbox-group>
        </el-form-item>
        <div class="new-offer__data-time">
          <div class="new-offer__data-time__item">
            <div class="new-offer__data-time__item-title">
              Рабочий день
            </div>
            <div class="new-offer__data-time__item-value">
              <el-form-item prop="workday_start">
                <el-time-picker
                  v-model="form.workday_start"
                  :disabled="!!form.disable_time_check"
                  format="HH:mm"
                  value-format="HH:mm"
                  @change="changeWorkdayStart"
                />
              </el-form-item>
              <span class="new-offer__data-time__item-separator">
                -
              </span>
              <el-form-item prop="workday_end">
                <el-time-picker
                  v-model="form.workday_end"
                  :disabled="!!form.disable_time_check"
                  format="HH:mm"
                  value-format="HH:mm"
                />
              </el-form-item>
              <!--              <el-checkbox-->
              <!--                class="new-offer__data-time__disabled"-->
              <!--                label="Отключить определение времени"-->
              <!--                v-model="form.disable_time_check"-->
              <!--              />-->
            </div>
            <div
              v-if="getWorkDuration"
              class="new-offer__data-time__item-result"
            >
              Длительность рабочего дня: {{ getWorkDuration }}
            </div>
          </div>
          <div class="new-offer__data-time__item">
            <div class="new-offer__data-time__item-title">
              Обеденный перерыв
            </div>
            <div class="new-offer__data-time__item-value">
              <el-form-item prop="dinner_start">
                <el-time-picker
                  v-model="form.dinner_start"
                  :disabled="!!form.disable_time_check"
                  format="HH:mm"
                  value-format="HH:mm"
                />
              </el-form-item>
              <span class="new-offer__data-time__item-separator">
                -
              </span>
              <el-form-item prop="dinner_end">
                <el-time-picker
                  v-model="form.dinner_end"
                  :disabled="!!form.disable_time_check"
                  format="HH:mm"
                  value-format="HH:mm"
                />
              </el-form-item>
            </div>
            <div
              v-if="getDinnerDuration"
              class="new-offer__data-time__item-result"
            >
              Длительность перерыва: {{ getDinnerDuration }}
            </div>
          </div>
        </div>
        <el-form-item label="Показывать ставку" prop="show_stavka">
          <el-checkbox v-model="form.show_stavka" />
        </el-form-item>
      </div>
      <div class="new-offer__form__footer">
        <el-button @click="cancelChanges">
          Отменить
        </el-button>
        <el-button
          type="primary"
          :disabled="isDisabledButton"
          native-type="submit"
        >
          Сохранить
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getHoursAndMinutes } from "@/utils/date-formatter";

export default {
  name: "AddOffer",
  data() {
    const checkTime = (rule, value, callback) => {
      if (!this.form.disable_time_check && !this.form[rule.field]) {
        callback(new Error("Обязательное поле"));
      } else {
        callback();
      }
    };
    const checkWorkweek = (rule, value, callback) => {
      if (!this.workweek.some(el => el)) {
        callback(new Error("Выберите рабочие дни"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      isNotTime: false,
      resource: "",
      workweek: [],
      isDisabled: false,
      isDisabledButton: false,
      form: {
        payment: null,
        pay_by_hours: false,
        piece_work_payment: false,
        workweek: {
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false
        },
        workday_start: null,
        workday_end: null,
        dinner_start: null,
        dinner_end: null,
        speciality_id: null,
        vacancy_id: null,
        show_stavka: false,
        disable_time_check: false
      },
      rules: {
        payment: [
          {
            required: true,
            message: "Обязательное поле",
            trigger: ["blur", "change"]
          }
        ],
        workweek: [{ validator: checkWorkweek, trigger: ["blur", "change"] }],
        workday_start: [{ validator: checkTime, trigger: ["blur", "change"] }],
        workday_end: [{ validator: checkTime, trigger: ["blur", "change"] }],
        dinner_start: [{ validator: checkTime, trigger: ["blur", "change"] }],
        dinner_end: [{ validator: checkTime, trigger: ["blur", "change"] }],
        speciality_id: [
          {
            required: true,
            message: this.specialitiesList?.length
              ? "Обязательное поле"
              : "Обязательное поле (заполните раздел специальности)",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  computed: {
    specialitiesList() {
      return this.$store.state.Projects.specialitiesList;
    },
    currentOffer() {
      return this.$store.state.Option.detailVacancy;
    },
    projectName() {
      if (this.$store.state.Projects.projectData)
        return this.$store.state.Projects.projectData.title;
      else return "";
    },
    vacanciesList() {
      return this.$store.state.Projects.vacanciesList;
    },
    activeVacancy() {
      return this.$store.state.Projects.activeVacancy;
    },
    currentCompany() {
      return this.$store.state.Option.detailCompany;
    },
    getWorkDuration() {
      return this.getTimeDuration(
        this.form.workday_start,
        this.form.workday_end
      );
    },
    getDinnerDuration() {
      return this.getTimeDuration(this.form.dinner_start, this.form.dinner_end);
    }
  },
  watch: {
    specialitiesList() {
      if (this.$route.query.for) {
        const elem = this.specialitiesList.find(
          el => el.id === this.$route.query.for
        );
        if (elem) {
          this.form.speciality_id = elem.id;
          this.isDisabled = true;
        }
      }
    },
    currentCompany: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$store.dispatch("Projects/getVacanciesList", {
            id: newValue.id
          });
        }
      }
    },
    activeVacancy: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setShedule(newValue);
        }
      }
    }
  },
  methods: {
    getHoursAndMinutes,
    getTimeDuration(startTime, endTime) {
      const start = new Date("01-01-2000 " + startTime).getTime();
      const finish = new Date("01-01-2000 " + endTime).getTime();
      if (start && finish) {
        let difference = finish - start;
        if (difference < 0) {
          const nextFinish = new Date("01-02-2000 " + endTime).getTime();
          return this.getHoursAndMinutes((nextFinish - start) / 1000);
        } else {
          return this.getHoursAndMinutes(difference / 1000);
        }
      }

      return "";
    },
    changeWorkdayStart(startTime) {
      if (startTime) {
        const currentTime = new Date("01-01-2000 " + startTime).getTime();
        const endTime = new Date(currentTime + 9 * 60 * 60 * 1000);
        const startDinnerTime = new Date(currentTime + 4 * 60 * 60 * 1000);
        const endDinnerTime = new Date(currentTime + 5 * 60 * 60 * 1000);
        this.form.workday_end = endTime.toLocaleTimeString();
        this.form.dinner_start = startDinnerTime.toLocaleTimeString();
        this.form.dinner_end = endDinnerTime.toLocaleTimeString();
      }
    },
    cancelChanges() {
      this.$store.commit("Option/closeOption");
    },
    submitForm() {
      this.isDisabledButton = true;
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.resource === "Сдельная") {
            this.form.piece_work_payment = true;
            this.form.pay_by_hours = false;
          } else {
            this.form.piece_work_payment = false;
            this.form.pay_by_hours = true;
          }
          this.cleanDays();
          this.workweek.forEach(el => {
            switch (el) {
              case "ПН":
                this.form.workweek.mon = true;
                break;
              case "ВТ":
                this.form.workweek.tue = true;
                break;
              case "СР":
                this.form.workweek.wed = true;
                break;
              case "ЧТ":
                this.form.workweek.thu = true;
                break;
              case "ПТ":
                this.form.workweek.fri = true;
                break;
              case "СБ":
                this.form.workweek.sat = true;
                break;
              case "ВС":
                this.form.workweek.sun = true;
                break;
            }
          });
          const data = this.form;
          if (this.$route.params.id) {
            data.project_id = this.$route.params.id;
          }
          const id = this.currentOffer ? this.currentOffer.id : null;
          this.currentOffer ? this.editOffer(data, id) : this.addOfer(data);
        } else {
          this.isDisabledButton = false;
          return false;
        }
      });
    },
    cleanDays() {
      for (const key in this.form.workweek) {
        this.form.workweek[key] = false;
      }
    },
    addOfer(data) {
      this.$store
        .dispatch("Projects/addOffer", data)
        .then(() => {
          this.$store.commit("Projects/setData", {
            label: "successCreate",
            data: true
          });
          this.$notify({
            title: "Успех",
            message: "Вакансия создана",
            type: "success"
          });
          this.isDisabledButton = false;
          this.$store.commit("Option/closeOption");
        })
        .catch(err => {
          console.log(err);
          this.isDisabledButton = false;
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    editOffer(data, id) {
      this.$store
        .dispatch("Projects/editOffer", { data, id })
        .then(() => {
          this.$store.commit("Projects/setData", {
            label: "successCreate",
            data: true
          });
          this.$notify({
            title: "Успех",
            message: "Изменения сохранены",
            type: "success"
          });
          this.isDisabledButton = false;
          this.$store.commit("Option/closeOption");
        })
        .catch(err => {
          console.log(err);
          this.isDisabledButton = false;
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    changeSpeciality(value) {
      this.loading = true;
      this.$store.dispatch("Projects/getVacancy", { id: value });
    },
    setShedule(vacancy) {
      this.form.workday_start = vacancy.workday_start;
      this.form.workday_end = vacancy.workday_end;
      this.form.dinner_start = vacancy.dinner_start;
      this.form.dinner_end = vacancy.dinner_end;
      this.form.workweek = vacancy.workweek;
      this.workweek.length = 0;
      for (const key in vacancy.workweek) {
        switch (key) {
          case "mon":
            if (vacancy.workweek[key]) {
              this.workweek.push("ПН");
            }
            break;
          case "tue":
            if (vacancy.workweek[key]) {
              this.workweek.push("ВТ");
            }
            break;
          case "wed":
            if (vacancy.workweek[key]) {
              this.workweek.push("СР");
            }
            break;
          case "thu":
            if (vacancy.workweek[key]) {
              this.workweek.push("ЧТ");
            }
            break;
          case "fri":
            if (vacancy.workweek[key]) {
              this.workweek.push("ПТ");
            }
            break;
          case "sat":
            if (vacancy.workweek[key]) {
              this.workweek.push("СБ");
            }
            break;
          case "sun":
            if (vacancy.workweek[key]) {
              this.workweek.push("ВС");
            }
            break;
        }
      }
      this.form.disable_time_check = !!vacancy.disable_time_check;
      this.loading = false;
    }
  },
  created() {
    this.form.workday_start = null;
    this.form.workday_end = null;
    this.form.dinner_start = null;
    this.form.dinner_end = null;
    this.workweek.length = 0;
    const companyId = this.$store.state.Auth.user.company;
    this.$store.commit("Option/setData", {
      label: "detailCompany",
      data: companyId
    });
  },
  mounted() {
    if (this.currentOffer) {
      for (const key in this.form) {
        this.form[key] = this.currentOffer[key];
      }
      this.form.show_stavka = !!this.currentOffer.show_stavka;
      this.form.disable_time_check = !!this.currentOffer.disable_time_check;
      this.currentOffer.piece_work_payment
        ? (this.resource = "Сдельная")
        : (this.resource = "Почасовая");
      for (const key in this.currentOffer.workweek) {
        if (this.currentOffer.workweek[key]) {
          switch (key) {
            case "mon":
              this.workweek.push("ПН");
              break;
            case "tue":
              this.workweek.push("ВТ");
              break;
            case "wed":
              this.workweek.push("СР");
              break;
            case "thu":
              this.workweek.push("ЧТ");
              break;
            case "fri":
              this.workweek.push("ПТ");
              break;
            case "sat":
              this.workweek.push("СБ");
              break;
            case "sun":
              this.workweek.push("ВС");
              break;
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("Option/setData", {
      label: "detailVacancy",
      data: null
    });
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.new-offer {
  padding-top: 4.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__name {
    padding-left: 4rem;
    margin-bottom: 4rem;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.3rem;
    color: $text;
    padding-right: 8rem;
  }

  &__data {
    max-width: 78.4rem;
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
    overflow-y: auto;

    .el-select {
      width: 100%;
    }

    &-payment {
      display: flex;
      align-items: center;

      .el-input {
        width: 15rem;
        margin-right: 2.4rem;
      }
    }

    &-time {
      &__item {
        margin-bottom: 2rem;

        &-title {
          font-size: 1.4rem;
          line-height: 1.9rem;
          color: #676973;
          margin-bottom: 0.4rem;
        }

        &-result {
          font-size: 1.2rem;
          line-height: 1.7rem;
          color: #676973;
          margin-top: 0.4rem;
        }

        &-value {
          display: flex;
          align-items: center;

          .el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 6.8rem;
          }

          .el-input__inner {
            padding: 0 1.4rem;
          }

          .el-input__prefix,
          .el-input__suffix {
            display: none;
          }

          .el-form-item {
            margin-bottom: 0;
          }

          .el-form-item__error {
            white-space: nowrap;
          }
        }

        &-separator {
          margin: 0 1.6rem;
        }
      }

      &__disabled {
        margin-left: 2.4rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $text;
      }
    }
  }

  &__days {
    .el-form-item__label {
      float: none;
      margin-bottom: 1rem;
      display: inline-block;
    }

    .el-checkbox {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 2.4rem;

      &__label {
        padding-left: 0;
        color: $form-def-placeholder-color;
      }
    }

    .el-checkbox-group {
      display: flex;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0;

    &__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 2px solid $border;
      padding: 2.4rem 4rem;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .new-offer__form {
    display: block;
  }
  .new-offer__data-payment {
    display: block;
    .el-form-item {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .el-radio-group {
      margin-bottom: 24px;
      margin-top: -8px;
    }
    .el-input {
      width: 100%;
      margin-right: 0;
    }
  }
  .new-offer__data-time__item {
    margin-bottom: 24px;
  }
  .new-offer__data-time__item-value {
    flex-wrap: wrap;
    .el-checkbox {
      width: 100%;
      margin-left: 0;
      margin-top: 16px;
    }
    .el-form-item {
      flex-grow: 1;
      width: calc(50% - 40px);
      .el-input {
        width: 100%;
      }
    }
  }
  .new-offer__form__footer {
    flex-direction: column;

    .el-button.el-button {
      margin: 0;
      width: auto;

      &.el-button--primary {
        width: 100%;
      }
    }
    .el-button:not(.el-button--primary) {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      order: 10;
      padding: 0;
      margin-top: 16px;
    }
  }
}
</style>
