var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profession-card"
  }, [_c('div', {
    staticClass: "profession-card__name"
  }, [_vm._v(" " + _vm._s(_vm.currentProfession ? _vm.currentProfession.name : 'Новая специальность') + " ")]), _c('el-form', {
    ref: "form",
    staticClass: "profession-card__form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm($event);
      }
    }
  }, [_c('div', {
    staticClass: "profession-card__data"
  }, [_c('div', {
    staticClass: "profession-card__row"
  }, [_c('div', {
    staticClass: "profession-card__form--full"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Специальность",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "profession-card__row"
  }, [_c('div', {
    staticClass: "profession-card__form--full"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Описание профиля деятельности",
      "prop": "description"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "resize": "none",
      "rows": 6
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)]), _vm.currentProfession && _vm.currentProfession.projects && _vm.currentProfession.projects.length ? _c('div', {
    staticClass: "profession-card__row"
  }, [_c('div', {
    staticClass: "profession-card__form--full"
  }, [_c('div', {
    staticClass: "profession-card__form-label"
  }, [_vm._v(" Вакансия открыта в проекте ")]), _c('ul', {
    staticClass: "profession-card__form-list"
  }, _vm._l(_vm.currentProfession.projects, function (project, index) {
    return _c('li', {
      key: index,
      staticClass: "profession-card__form-list-item"
    }, [_vm._v(" " + _vm._s(project.title) + " ")]);
  }), 0)])]) : _vm._e(), _vm.currentProfession && _vm.currentProfession.users && _vm.currentProfession.users.length ? _c('div', {
    staticClass: "profession-card__row"
  }, [_c('div', {
    staticClass: "profession-card__form--full"
  }, [_c('div', {
    staticClass: "profession-card__form-label"
  }, [_vm._v("Пользователи по специальности:")]), _c('ul', {
    staticClass: "profession-card__form-list"
  }, _vm._l(_vm.currentProfession.users, function (user, index) {
    return _c('li', {
      key: user.id,
      staticClass: "profession-card__form-list-item"
    }, [_c('router-link', {
      attrs: {
        "to": '/employees/' + user.id
      }
    }, [_vm._v(_vm._s(user.name))])], 1);
  }), 0)])]) : _vm._e()]), _c('div', {
    staticClass: "profession-card__form__footer",
    class: {
      'profession-card__form__footer--end': !(_vm.currentProfession && _vm.currentProfession.id)
    }
  }, [_vm.currentProfession && _vm.currentProfession.id ? _c('div', {
    staticClass: "profession-card__form__footer-options"
  }, [_c('div', {
    staticClass: "profession-card__form__footer-options__item",
    attrs: {
      "title": "Добавить в проект"
    },
    on: {
      "click": _vm.addToProject
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/icons/icon-to-project.svg")
    }
  })]), _c('div', {
    staticClass: "profession-card__form__footer-options__item",
    attrs: {
      "title": "Удалить"
    },
    on: {
      "click": _vm.removeProfession
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/icons/icon-delete.svg")
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "profession-card__form__footer-actions"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancelChanges
    }
  }, [_vm._v(" Отменить ")]), _vm.currentProfession && _vm.currentProfession.id ? _c('el-button', {
    attrs: {
      "type": "primary el-button--stretch",
      "native-type": "submit",
      "disabled": _vm.isDisabled
    }
  }, [_vm._v(" Сохранить изменения ")]) : _c('el-button', {
    attrs: {
      "type": "primary el-button--stretch",
      "native-type": "submit"
    }
  }, [_vm._v(" Сохранить ")])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }