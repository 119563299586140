var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "new-offer"
  }, [_vm.projectName ? _c('div', {
    staticClass: "new-offer__name"
  }, [_vm._v(" " + _vm._s(_vm.currentOffer ? "Редактирование вакансии в проекте" : "Новая вакансия в проекте") + " "), _c('br'), _vm._v(" " + _vm._s(_vm.projectName) + " ")]) : _vm._e(), _c('el-form', {
    ref: "form",
    staticClass: "new-offer__form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm($event);
      }
    }
  }, [_c('div', {
    staticClass: "new-offer__data"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Специальность",
      "prop": "speciality_id"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "disabled": _vm.isDisabled,
      "placeholder": "Все специальности"
    },
    model: {
      value: _vm.form.speciality_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "speciality_id", $$v);
      },
      expression: "form.speciality_id"
    }
  }, _vm._l(_vm.specialitiesList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "new-offer__data-payment"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Оплата (баллов)",
      "prop": "payment"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.payment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "payment", $$v);
      },
      expression: "form.payment"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "resource"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.resource,
      callback: function callback($$v) {
        _vm.resource = $$v;
      },
      expression: "resource"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": "Почасовая",
      "value": 'pay_by_hours'
    }
  }), _c('el-radio', {
    attrs: {
      "label": "Сдельная",
      "value": 'piece_work_payment'
    }
  })], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "График работы исходя из вакансии",
      "prop": "vacancy_id"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "disabled": _vm.isDisabled,
      "placeholder": "Выберите график работы исходя из вакансии"
    },
    on: {
      "change": _vm.changeSpeciality
    },
    model: {
      value: _vm.form.vacancy_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vacancy_id", $$v);
      },
      expression: "form.vacancy_id"
    }
  }, _vm._l(_vm.vacanciesList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.title,
        "value": item.id
      }
    });
  }), 1)], 1), _c('el-form-item', {
    staticClass: "new-offer__days",
    attrs: {
      "label": "График на неделю",
      "prop": "workweek"
    }
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.workweek,
      callback: function callback($$v) {
        _vm.workweek = $$v;
      },
      expression: "workweek"
    }
  }, [_c('el-checkbox', {
    attrs: {
      "label": "ПН",
      "name": "workweek"
    }
  }), _c('el-checkbox', {
    attrs: {
      "label": "ВТ",
      "name": "workweek"
    }
  }), _c('el-checkbox', {
    attrs: {
      "label": "СР",
      "name": "workweek"
    }
  }), _c('el-checkbox', {
    attrs: {
      "label": "ЧТ",
      "name": "workweek"
    }
  }), _c('el-checkbox', {
    attrs: {
      "label": "ПТ",
      "name": "workweek"
    }
  }), _c('el-checkbox', {
    attrs: {
      "label": "СБ",
      "name": "workweek"
    }
  }), _c('el-checkbox', {
    attrs: {
      "label": "ВС",
      "name": "workweek"
    }
  })], 1)], 1), _c('div', {
    staticClass: "new-offer__data-time"
  }, [_c('div', {
    staticClass: "new-offer__data-time__item"
  }, [_c('div', {
    staticClass: "new-offer__data-time__item-title"
  }, [_vm._v(" Рабочий день ")]), _c('div', {
    staticClass: "new-offer__data-time__item-value"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "workday_start"
    }
  }, [_c('el-time-picker', {
    attrs: {
      "disabled": !!_vm.form.disable_time_check,
      "format": "HH:mm",
      "value-format": "HH:mm"
    },
    on: {
      "change": _vm.changeWorkdayStart
    },
    model: {
      value: _vm.form.workday_start,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "workday_start", $$v);
      },
      expression: "form.workday_start"
    }
  })], 1), _c('span', {
    staticClass: "new-offer__data-time__item-separator"
  }, [_vm._v(" - ")]), _c('el-form-item', {
    attrs: {
      "prop": "workday_end"
    }
  }, [_c('el-time-picker', {
    attrs: {
      "disabled": !!_vm.form.disable_time_check,
      "format": "HH:mm",
      "value-format": "HH:mm"
    },
    model: {
      value: _vm.form.workday_end,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "workday_end", $$v);
      },
      expression: "form.workday_end"
    }
  })], 1)], 1), _vm.getWorkDuration ? _c('div', {
    staticClass: "new-offer__data-time__item-result"
  }, [_vm._v(" Длительность рабочего дня: " + _vm._s(_vm.getWorkDuration) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "new-offer__data-time__item"
  }, [_c('div', {
    staticClass: "new-offer__data-time__item-title"
  }, [_vm._v(" Обеденный перерыв ")]), _c('div', {
    staticClass: "new-offer__data-time__item-value"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "dinner_start"
    }
  }, [_c('el-time-picker', {
    attrs: {
      "disabled": !!_vm.form.disable_time_check,
      "format": "HH:mm",
      "value-format": "HH:mm"
    },
    model: {
      value: _vm.form.dinner_start,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dinner_start", $$v);
      },
      expression: "form.dinner_start"
    }
  })], 1), _c('span', {
    staticClass: "new-offer__data-time__item-separator"
  }, [_vm._v(" - ")]), _c('el-form-item', {
    attrs: {
      "prop": "dinner_end"
    }
  }, [_c('el-time-picker', {
    attrs: {
      "disabled": !!_vm.form.disable_time_check,
      "format": "HH:mm",
      "value-format": "HH:mm"
    },
    model: {
      value: _vm.form.dinner_end,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dinner_end", $$v);
      },
      expression: "form.dinner_end"
    }
  })], 1)], 1), _vm.getDinnerDuration ? _c('div', {
    staticClass: "new-offer__data-time__item-result"
  }, [_vm._v(" Длительность перерыва: " + _vm._s(_vm.getDinnerDuration) + " ")]) : _vm._e()])]), _c('el-form-item', {
    attrs: {
      "label": "Показывать ставку",
      "prop": "show_stavka"
    }
  }, [_c('el-checkbox', {
    model: {
      value: _vm.form.show_stavka,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "show_stavka", $$v);
      },
      expression: "form.show_stavka"
    }
  })], 1)], 1), _c('div', {
    staticClass: "new-offer__form__footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancelChanges
    }
  }, [_vm._v(" Отменить ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": _vm.isDisabledButton,
      "native-type": "submit"
    }
  }, [_vm._v(" Сохранить ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }