export default function dateFilter(value, format = 'date') {
  if (value) {
    const options = {}

    if (format.includes('date')) {
      options.day = '2-digit'
      options.month = '2-digit'
      options.year = 'numeric'
    }

    if (format.includes('time')) {
      options.hour = '2-digit'
      options.minute = '2-digit'
    }
    return (value[value.length - 1] === 'Z') ? new Date(value).toLocaleString('ru-RU', options).split(", ").join("\n") : new Date(value.replace(/-/g, "/")).toLocaleString('ru-RU', options).split(", ").join("\n")
  }
}
