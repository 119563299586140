<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  sockets: {
    connect() {
      console.log("client connected");
    },
    message(data) {
      console.info(data);
      this.$store.commit("Chat/setNewMessage", data);
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "land-layout";
    },
    currentUser() {
      return this.$store.state.Auth.user;
    },
    currentOption() {
      return this.$store.state.Option.currentOption;
    },
    messages() {
      return this.$store.state.Chat.messages;
    }
  },
  created() {
    axios.interceptors.response.use(undefined, err => {
      return new Promise(() => {
        if (
          err.response.status === 401 &&
          err.response.config &&
          !err.response.config.__isRetryRequest
        ) {
          this.$store.dispatch("Auth/logout").then(() => {
            this.$store.commit("Auth/resetState");
            this.$store.commit("Company/resetState");
            this.$store.commit("Employee/resetState");
            this.$store.commit("Professions/resetState");
            this.$store.commit("Projects/resetState");
            if (this.$route.name !== "Login") {
              if (this.$route.name !== "Landing") {
                if (this.$route.name !== "Agreement") {
                  this.$router.push({ name: "Login" });
                }
              }
            }
          });
        }
        throw err;
      });
    });
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const userRole =
      localStorage.getItem("user-role") || sessionStorage.getItem("user-role");
    this.$store.commit("Auth/auth_success", { token, userRole });
    if (token) {
      const unwatch = this.$watch(
        () => this.$route,
        (route, prevRoute) => {
          if (route.name == "Landing" || route.name == "Login") {
            this.$router.push("Projects");
          }
          this.activeIndex = route.name;
          unwatch();
        }
      );

      if (this.$route.name == "Landing") {
        // this.route.push('Employees')
      }
      this.$store.dispatch("Tariffs/getCurrentTariff");
      this.$store.dispatch("Auth/getUser").then(() => {
        this.$store.dispatch("Chat/getChats");
        const user = { id: this.currentUser.id, email: this.currentUser.email };
        this.$socket.emit("join", user, data => {
          console.info("join");
          if (typeof data === "string") {
            console.error(data);
          } else {
            console.info("no string");
          }
        });

        this.$socket.emit("isOnline", this.currentUser.id, data => {
          console.info("isOnline: ", data, 4444);
        });
      });
    }
  }
};
</script>
