import axios from "axios";

const initialState = {
  tariffsList: null,
  currentTariff: null
};

const state = Object.assign({}, initialState);

const getters = {
  currentTariff: state => {
    return (
      state.currentTariff || {
        id: 0,
        name: "Пробный",
        project_count: 1,
        speciality_count: 1,
        user_count: 1,
        can_download_project_stats: 0,
        can_download_user_stats: 0,
        tariff_periods: [{ price: "Бесплатно" }]
      }
    );
  }
};

const mutations = {
  setData(state, data) {
    state[data.label] = data.data;
  },
  resetState(state) {
    for (let prop in state) {
      state[prop] = initialState[prop];
    }
  }
};

const actions = {
  getTariffs({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/tariffs`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "tariffsList",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getCurrentTariff({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/user/tariff`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "currentTariff",
            data: resp.data.data?.length ? resp.data.data[0] : null
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  buyTariff({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/orders`,
        method: "POST",
        data
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
