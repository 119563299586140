<template>
    <div class="land-layout">
        <slot />
    </div>
  </template>
  
  <script>
  export default {
    name: 'LandLayout'
  }
  </script>
  
  <style scoped lang="scss">
  @import "src/assets/styles/helpers/variables";
  @import "src/assets/styles/helpers/mixin";
  
  </style>
  