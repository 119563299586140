var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app",
    class: {
      'app--closed': _vm.isClosed
    }
  }, [_c('div', {
    staticClass: "app-sidebar"
  }, [_c('Sidebar', {
    on: {
      "change-view": _vm.checkSidebarView
    }
  })], 1), _c('div', {
    staticClass: "app-content"
  }, [_c('div', {
    staticClass: "app-header"
  }, [_c('div', {
    staticClass: "app-header__logo"
  }, [_c('router-link', {
    staticClass: "hide-lg",
    attrs: {
      "to": {
        name: 'Employees'
      }
    }
  }, [_c('span', {
    staticClass: "app-header__logo-icon"
  })])], 1), _c('div', {
    staticClass: "app-search"
  }, [_c('Search')], 1), _c('div', {
    staticClass: "app-user"
  }, [_c('UserCard')], 1)]), _c('div', {
    staticClass: "app-page"
  }, [_vm._t("default")], 2)]), _c('AddOptionBg'), _c('AddOption')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }