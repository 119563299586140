const state = () => ({
  currentOption: null,
  detailCompany: null,
  detailProfession: null,
  detailVacancy: null,
})

const getters = {
  currentOption: state => {
    return state.currentOption
  },
}

const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  closeOption(state) {
    state.currentOption = null
    state.detailCompany = null
    state.detailProfession = null
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
