import axios from 'axios'

const state = () => ({
  currentChat: null,
  messages: null,
  newMessages: [],
  historyMessages: null,
})

const getters = {
  allMessages: (state) => {
    const arr = []
    if (state.historyMessages) {
      state.historyMessages.forEach(el => {
        if (el.toUser) arr.push({ id: el.toUser.id, count: el.count_unread, type: 'user' })
        else if (el.project) arr.push({ id: el.project.id, count: el.count_unread, type: 'project' })
      })
    }
    if (state.newMessages && state.newMessages.length) {
      state.newMessages.forEach(el => {
        if (el.from_user_id) {
          const elem = arr.find(el2 => el2.id === el.from_user_id)
          if (elem) {
            elem.read ? (elem.count = 0) : (elem.count = elem.count + 1)
          } else {
            const count = el.read ? 0 : 1
            const read = !!el.read
            arr.push({ id: el.from_user_id, count, type: 'user', read })
          }
        } else if (el.project_id) {
          const elem = arr.find(el2 => el2.id === el.project_id)
          if (elem) {
            el.read ? (elem.count = 0) : (elem.count = elem.count + 1)
          } else {
            const count = el.read ? 0 : 1
            const read = !!el.read
            arr.push({ id: el.project_id, count, type: 'project', read })
          }
        }
      })
    }
    return arr
  },
  globalCount: (state, getters) => {
    let count = 0
    if (getters.allMessages) {
      getters.allMessages.forEach(el => {
        count = count + el.count
      })
    }
    return count
  },
}

const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  setNewMessage(state, data) {
    if (state.messages) {
      state.messages.push(data)
    }
    if (state.newMessages) {
      state.newMessages.push(data)
    }
  },
  changeCounts(state, data) {
    if (state.historyMessages && state.historyMessages.length) {
      const arr = []
      state.historyMessages.forEach(el => {
        if ((el.toUser && el.toUser.id === data) || (el.project && el.project.id === data)) {
          el.count_unread = 0
          el.read = true
        }
        arr.push(el)
      })
      state.historyMessages = arr
    }
    if (state.newMessages && state.newMessages.length) {
      const arr = []
      state.newMessages.forEach(el => {
        if (el.to_user_id === data || el.project_id === data) {
          el.count = 0
          el.read = true
        }
        arr.push(el)
      })
      state.newMessages = arr
    }
  },
  readUserMessage(state, idUser) {
    if (Array.isArray(state.newMessages)) {
      state.newMessages = state.newMessages.filter(el => {
        return !el.from_user_id == idUser
      })
    }
  }
}

const actions = {
  getChats({ commit }) {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_URL + '/message', method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'historyMessages', data: resp.data.data });
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getMessagesForProject({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${process.env.VUE_APP_API_URL}/message/project/${data.id}?page=${data.page}&pageCount=20`, method: 'GET' })
        .then(resp => {
          // commit('setData', { label: 'messages', data: resp.data.data.data.reverse() })
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getMessagesForUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_URL + '/message/user/' + id, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'messages', data: resp.data.data.data.reverse() })
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendMessage(ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_URL + '/message', data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
