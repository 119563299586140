<template>
  <transition name="el-fade-in">
    <div v-if="currentOption" class="option-bg" @click="closeOption"></div>
  </transition>
</template>

<script>
export default {
  name: 'AddOptionBg',
  computed: {
    currentOption() {
      return this.$store.state.Option.currentOption
    },
  },
  methods: {
    closeOption() {
      this.$store.commit('Option/closeOption')
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.option-bg {
  z-index: 550;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#1c283c, 0.7);
}
</style>
