import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/auth";
import Search from "./modules/search";
import Company from "./modules/company";
import Option from "./modules/option";
import Employee from "./modules/employee";
import Projects from "./modules/projects";
import Professions from "./modules/professions";
import Chat from "./modules/chat";
import Tariffs from "./modules/tariffs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Search,
    Company,
    Option,
    Employee,
    Projects,
    Professions,
    Chat,
    Tariffs
  }
});
