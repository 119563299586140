import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dateFilter from '@/filters/date.filter'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

const socketConnection = SocketIO('https://websocket.wiplan.ru/')

import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import './assets/styles/plugins/elementTheme.scss'

import './assets/styles/style.scss'

import AuthLayout from './layouts/AuthLayout'
import BaseLayout from './layouts/BaseLayout'
import LandLayout from './layouts/LandLayout'

Vue.use(Element, { locale })
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: socketConnection,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_',
    },
  }),
)
Vue.component('AuthLayout', AuthLayout)
Vue.component('BaseLayout', BaseLayout)
Vue.component('LandLayout', LandLayout)

Vue.config.productionTip = false

Vue.filter('date', dateFilter)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
