<template>
  <div class="profession-card">
    <div class="profession-card__name">
      {{ currentProfession ? currentProfession.name : 'Новая специальность' }}
    </div>
    <el-form
      class="profession-card__form"
      ref="form"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
      @submit.native.prevent="submitForm"
    >
      <div class="profession-card__data">
        <div class="profession-card__row">
          <div class="profession-card__form--full">
            <el-form-item label="Специальность" prop="name">
              <el-input v-model="form.name" type="text" />
            </el-form-item>
          </div>
        </div>
        <div class="profession-card__row">
          <div class="profession-card__form--full">
            <el-form-item
              label="Описание профиля деятельности"
              prop="description"
            >
              <el-input
                v-model="form.description"
                type="textarea"
                resize="none"
                :rows="6"
              />
            </el-form-item>
          </div>
        </div>
        <div
          class="profession-card__row"
          v-if="currentProfession && currentProfession.projects && currentProfession.projects.length"
        >
          <div class="profession-card__form--full">
            <div class="profession-card__form-label">
              Вакансия открыта в проекте
            </div>
            <ul class="profession-card__form-list">
              <li
                class="profession-card__form-list-item"
                v-for="(project, index) in currentProfession.projects"
                :key="index"
              >
                {{ project.title }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="profession-card__row"
          v-if="currentProfession && currentProfession.users && currentProfession.users.length"
        >
          <div class="profession-card__form--full">
            <div class="profession-card__form-label">Пользователи по специальности:</div>
            <ul class="profession-card__form-list">
              <li
                class="profession-card__form-list-item"
                v-for="(user, index) in currentProfession.users"
                :key="user.id"
              >
                <router-link :to="'/employees/' + user.id">{{ user.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="profession-card__form__footer"
        :class="{ 'profession-card__form__footer--end': !(currentProfession && currentProfession.id) }"
      >
        <div
          v-if="currentProfession && currentProfession.id"
          class="profession-card__form__footer-options"
        >
          <div
            class="profession-card__form__footer-options__item"
            @click="addToProject"
            title="Добавить в проект"
          >
            <img src="../../assets/icons/icon-to-project.svg" />
          </div>
          <div
            class="profession-card__form__footer-options__item"
            @click="removeProfession"
            title="Удалить"
          >
            <img src="../../assets/icons/icon-delete.svg" />
          </div>
        </div>
        <div class="profession-card__form__footer-actions">
          <el-button @click="cancelChanges">
            Отменить
          </el-button>
          <el-button
            v-if="currentProfession && currentProfession.id"
            type="primary el-button--stretch"
            native-type="submit"
            :disabled="isDisabled"
          >
            Сохранить изменения
          </el-button>
          <el-button
            v-else
            type="primary el-button--stretch"
            native-type="submit"
          >
            Сохранить
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ProfessionCard',
  data() {
    return {
      isDisabled: false,
      form: {
        name: null,
        description: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
        description: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  computed: {
    currentProfession() {
      return this.$store.state.Option.detailProfession
    },
  },
  mounted() {
    if (this.currentProfession) {
      for (const key in this.form) {
        if (this.currentProfession[key]) {
          this.form[key] = this.currentProfession[key]
        }
      }
    }
  },
  methods: {
    cancelChanges() {
      this.$store.commit('Option/closeOption')
    },
    submitForm() {
      this.isDisabled = true
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.currentProfession && this.currentProfession.id
            ? this.editProfession()
            : this.createProfession()
        } else {
          this.isDisabled = false
          return false
        }
      })
    },
    createProfession() {
      const data = {
        name: this.form.name,
        description: this.form.description,
      }
      this.$store
        .dispatch('Professions/createProfession', data)
        .then(() => {
          this.$notify({
            title: 'Успех',
            message: 'Специальность создана',
            type: 'success',
          })
          this.$store.commit('Professions/setData', {
            label: 'successCreate',
            data: true,
          })
          this.isDisabled = false
          this.$store.commit('Option/closeOption')
        })
        .catch(err => {
          console.error(err.response)
          this.isDisabled = false
          this.$notify({
            title: 'Ошибка',
            message: 'Ошибка. Специальность не создана',
            type: 'error',
          })
        })
    },
    editProfession() {
      const data = {
        name: this.form.name,
        description: this.form.description,
      }
      this.$store
        .dispatch('Professions/editProfession', {
          data,
          id: this.currentProfession.id,
        })
        .then(() => {
          this.$notify({
            title: 'Успех',
            message: 'Изменения сохранены',
            type: 'success',
          })
          this.$store.commit('Professions/setData', {
            label: 'successEdit',
            data: true,
          })
          this.isDisabled = false
          this.$store.commit('Option/closeOption')
        })
        .catch(err => {
          this.isDisabled = false
          this.$notify({
            title: 'Ошибка',
            message: 'Ошибка. Попробуйте еще раз',
            type: 'error',
          })
          console.error(err.response)
        })
    },
    removeProfession() {
      this.$confirm(
        `<div class="remove-message">
      <img src="${require('@/assets/icons/icon-delete-red.svg')}" alt="Удаление специальности">
      <div>
        <div class="remove-message__title">
          Удаление специальности
        </div>
        <div class="remove-message__text">
          Вы действительно хотите удалить запись о специальности из базы данных и все ее связи?
        </div>
      </div>
    </div>`,
        {
          customClass: 'remove-message__box',
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отменить',
          showClose: false,
        },
      ).then(() => {
        this.$store
          .dispatch('Professions/deleteProfession', this.currentProfession.id)
          .then(() => {
            this.$store.commit('Professions/setData', {
              label: 'successRemove',
              data: true,
            })
            this.$store.commit('Option/closeOption')
            this.$notify({
              title: 'Успех',
              message: 'Специальность удалена',
              type: 'success',
            })
          })
          .catch(err => {
            console.error(err)
            this.$notify({
              title: 'Ошибка',
              message: 'Ошибка. Специальность не удалена',
              type: 'error',
            })
          })
      })
    },
    addToProject() {
      this.$router.push({ path: '/add-to-project?for=' + this.currentProfession.id })
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.profession-card {
  padding-top: 4.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 78.4rem;
  max-width: 100%;

  &__name {
    padding-left: 4rem;
    padding-right: 8rem;
    margin-bottom: 4rem;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.3rem;
    color: $text;
    word-break: break-all;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0;

    &--left {
      $width: 50%;

      width: $width;
      max-width: $width;
      flex: 1 0 $width;
      padding: 0 1.2rem 0 4rem;
    }

    &--right {
      $width: 50%;

      width: $width;
      max-width: $width;
      flex: 1 0 $width;
      padding: 0 4rem 0 1.2rem;
    }

    &--full {
      $width: 100%;

      width: $width;
      max-width: $width;
      flex: 1 0 $width;
      padding: 0 4rem;
    }

    &-label {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #676973;
      margin-bottom: 1.2rem;
    }

    &-list {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #676973;
      padding-left: 1.8rem;

      &-item {
        margin-bottom: 0.8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 2px solid $border;
      padding: 2.4rem 4rem;

      &--end {
        justify-content: flex-end;
      }

      &-options {
        display: flex;
        align-items: center;

        &__item {
          margin-right: 2.6rem;
          cursor: pointer;
          transition: 0.3s ease-out;

          &:hover {
            opacity: 0.6;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &-actions {
        display: flex;
      }
    }
  }
}

@media (max-width: $screen-xs-max){
	.profession-card{
		padding-top: 24px;
	}
	.profession-card__name{
		padding-bottom: 26px;
		padding-left: 16px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 0;
		border-bottom: 1px solid #E3E5EC;
	}
	.profession-card__data{
		flex-direction: column;
	}
	.profession-card__form{
		overflow-y: auto;
		padding-top: 16px;

		.el-form-item{
			width: 100%;
		}

		.el-form-item__label{
			font-size: 14px;
			line-height: 20px;
			color: #676973;
		}
	}
	.profession-card__form--full{
		padding-left: 16px;
		padding-right: 16px;
	}
	.profession-card__form__footer{
		border-top: none;
		padding: 16px;
		padding-bottom: 32px;
	}
}

@media (max-width: $screen-3xs-max){
	.profession-card__form__footer{
		flex-direction: column;
		align-items: flex-start;
	}
	.profession-card__form__footer-options{
		margin-bottom: 32px;
	}
	.profession-card__form__footer-options__item{
		display: flex;
	}
	.profession-card__form__footer-actions{
		flex-direction: column;
		align-items: center;
		width: 100%;

		.el-button--primary{
			order: -10;
			width: 100%;
		}

		.el-button{
			margin: 0;
		}

		.el-button--default{
			margin-top: 16px;
			padding: 0;
			border-top: none;
			border-left: none;
			border-right: none;
			border-radius: 0;
		}
	}
}
</style>
