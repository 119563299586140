var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userData ? _c('div', {
    staticClass: "user-card"
  }, [_vm.userData.role !== 'admin' && _vm.count > 0 ? _c('a', {
    staticClass: "user-card__notice",
    attrs: {
      "href": "#",
      "title": "Уведомления"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openChat($event);
      }
    }
  }, [_vm.count > 0 ? _c('span', {
    staticClass: "user-card__notice-count",
    domProps: {
      "textContent": _vm._s(_vm.count)
    }
  }) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "user-card__info",
    class: {
      'user-card_active': _vm.isMenu
    }
  }, [_c('transition', {
    attrs: {
      "name": "el-fade-in"
    }
  }, [_vm.isMenu ? _c('div', {
    staticClass: "user-card__drop"
  }, [_c('div', {
    staticClass: "user-card__drop-item"
  }, [_c('span', {
    staticClass: "user-card__drop-link user-card__drop-link_change-pass",
    on: {
      "click": _vm.changePassword
    }
  }, [_vm._v(" Изменить пароль ")])]), _vm.currentTariff ? _c('div', {
    staticClass: "user-card__drop-item"
  }, [_c('router-link', {
    staticClass: "user-card__drop-link user-card__drop-link_tariff",
    attrs: {
      "to": "/tariffs"
    }
  }, [_vm._v(" Тариф " + _vm._s(_vm.currentTariff.name) + " ")])], 1) : _vm._e(), _c('div', {
    staticClass: "user-card__drop-item"
  }, [_c('a', {
    staticClass: "user-card__drop-link user-card__drop-link_logout",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.logout($event);
      }
    }
  }, [_vm._v(" Выйти из акаунта ")])]), _vm.userRole !== 'admin' ? _c('div', {
    staticClass: "user-card__drop-item"
  }, [_c('div', {
    staticClass: "user-card__drop-link user-card__drop-link_delete",
    on: {
      "click": _vm.removeCompany
    }
  }, [_vm._v(" Удалить компанию ")])]) : _vm._e()]) : _vm._e()]), _c('a', {
    staticClass: "user-card__control",
    attrs: {
      "href": "#",
      "title": _vm.userData.name
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.isMenu = !_vm.isMenu;
      }
    }
  }, [_c('div', {
    staticClass: "user-card__avatar"
  }, [_c('img', {
    staticClass: "user-card__avatar-img",
    attrs: {
      "src": require("@/assets/images/avatar.jpg"),
      "alt": _vm.userData.name
    }
  })]), _c('div', {
    staticClass: "user-card__person"
  }, [_c('div', {
    staticClass: "user-card__login"
  }, [_vm._v(_vm._s(_vm.userData.name))]), _vm.userData.company ? _c('div', {
    staticClass: "user-card__company"
  }, [_vm._v(" " + _vm._s(_vm.userData.company.title) + " ")]) : _vm._e()])])], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }