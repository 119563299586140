<template>
  <div class="chat">
    <div class="chat-header">
      <div
        v-if="currentChat && currentChat.type === 'user'"
        class="chat-header__user"
      >
        <div class="chat-header__user-photo">
          <img v-if="currentChat.image" :src="currentChat.image">
          <span v-else>
            {{currentChat.name ? currentChat.name.substring(0, 1)  : ''}}
          </span>
        </div>
        <div class="chat-header__user-info">
          <div class="chat-header__user-name">
            {{ currentChat.name }}
          </div>
          <div class="chat-header__user-status">
            Не в сети
          </div>
        </div>
      </div>
      <div
        v-if="currentChat && currentChat.type === 'project'"
        class="chat-header__group"
      >
        ЧАТ проекта {{ currentChat.name }}
      </div>
    </div>
    <div class="chat-messages">
      <infinite-loading direction="top" @infinite="infiniteHandler">
        <div class="chat-messages__notify" slot="no-more">Все сообщения загружены</div>
        <div class="chat-messages__notify" slot="no-results">Нет сообщений</div>
      </infinite-loading>
      <div class="chat-messages__wrapper">
        <div
          v-for="(mes, index) in messages"
          :key="index"
          class="chat-message"
          :class="{'chat-message--sender' : mes.from_user_id !== currentUserId}"
        >
          <div class="chat-message__data">
            <div v-if="mes.name" class="chat-message__name" v-text="mes.name" />
            <div class="chat-message__text" v-text="mes.text" />
          </div>
          <div class="chat-message__photo">
            <img v-if="mes.image" :src="fixedImage(mes.image)">
            <span v-else-if="mes.name">
               {{mes.name ? mes.name.substring(0, 1)  : ''}}
            </span>
          </div>
          <div class="chat-message__date">
            {{ fixedDate(mes.created_at) }}
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer">
      <textarea
				rows="1"
        name="message"
        placeholder="Введите текст сообщения..."
        v-model="newMessage"
        @keydown.enter.prevent="sendMessage"
      />
      <button
        class="chat-footer__send"
        :class="{'chat-footer__send--active': newMessage}"
        :disabled="!newMessage"
        @click="sendMessage"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 2L11 13"
            stroke="#A2A7BE"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22 2L15 22L11 13L2 9L22 2Z"
            stroke="#A2A7BE"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Chat',
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      newMessage: '',
      page: 1,
      list: [],
    }
  },
  computed: {
    currentChat() {
      return this.$store.state.Chat.currentChat
    },
    messages() {
      return this.$store.state.Chat.messages
    },
    newMessages() {
      return this.$store.state.Chat.newMessages
    },
    currentUserId() {
      return this.$store.state.Auth.user.id
    },
  },
  watch: {
    newMessages: {
      deep: true,
      handler() {
        setTimeout(() => {
          const block = document.querySelector('.chat-messages')
          if (block) block.scrollTop = block.scrollHeight
        }, 200)
      },
    },
  },
  methods: {
    infiniteHandler($state) {
      if (this.currentChat.type === 'project') {
        this.$store.dispatch('Chat/getMessagesForProject', { page: this.page, id: this.currentChat.id })
          .then((data) => {
            if (data.data.data.data.length) {
              this.page += 1
              this.list.unshift(...data.data.data.data.reverse())
              this.$store.commit('Chat/setData', { label: 'messages', data: this.list })
              $state.loaded()
            } else {
              $state.complete()
            }
          })
      } else {
        this.$store.dispatch('Chat/getMessagesForUser', this.currentChat.id)
      }
    },
    fixedImage(image) {
      return process.env.VUE_APP_BASE_URL + image
    },
    fixedDate(date) {
      const newDate = date.split('T')
      const dateInfo = newDate[0].split('-')
      const timeInfo = newDate[1]
      return dateInfo[2] + '.' + dateInfo[1] + '.' + dateInfo[0] + ' ' + timeInfo.substring(0, 5)
    },
    sendMessage() {
      const data = {
        text: this.newMessage,
      }
      this.currentChat.type === 'project'
        ? (data.project_id = this.currentChat.id)
        : (data.to_user_id = this.currentChat.id)
      const date = new Date()
		date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
      const message = {
        created_at: date.toISOString(),
        from_user_id: this.$store.state.Auth.user.id,
        image: this.$store.state.Auth.user.image,
        name: this.$store.state.Auth.user.name,
        text: this.newMessage,
      }
      this.$store.commit('Chat/setNewMessage', message)
      this.$store.dispatch('Chat/sendMessage', data)
        .then(() => {
          this.newMessage = ''
        })
        .catch(err => {
          // todo Очищать поле, если возвращается ошибка 500. Временное решение. См. задачу
          if (err.message === 'Request failed with status code 500') {
            this.newMessage = ''
          }
        })
    },
    getMessages() {
      if (this.currentChat.type === 'project') {
        this.$store.dispatch('Chat/getMessagesForProject', this.currentChat.id)
          .then(() => {
            const block = document.querySelector('.chat-messages')
            if (block) block.scrollTop = block.scrollHeight
            this.$store.commit('Chat/changeCounts', this.currentChat.id)
          })
      } else {
        this.$store.dispatch('Chat/getMessagesForUser', this.currentChat.id)
      }
    },
  },
  mounted() {
    // if (this.currentChat) this.getMessages()
  },
  beforeDestroy() {
    this.$store.commit('Chat/setData', { label: 'messages', data: null })
    this.$store.commit('Chat/readUserMessage', this.currentChat.id)
    this.$store.dispatch('Chat/getChats');
  },
}
</script>

<style lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.chat {
  background: #fff;
  max-width: 100%;
  width: 78rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-header {
    border-bottom: 2px solid $border;
    height: 10.5rem;
    display: flex;
    align-items: center;
    padding-left: 4rem;

    &__user {
      display: flex;
      align-items: center;

      &-photo {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 0.8rem;
        background: #103887;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.4rem;
        font-weight: 600;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-name {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $text;
      }

      &-status {
        font-size: 1rem;
        line-height: 1.4rem;
        color: $form-def-placeholder-color;
      }
    }

    &__group {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3.3rem;
      color: $text;
    }
  }

  &-messages {
    height: calc(100% - 19.5rem);
    overflow-y: auto;

    &__wrapper {
      padding: 4rem;
    }

    &__notify {
      font-size: 1.4rem;
    }

    // todo Hide temporarily
    .infinite-status-prompt {
      display: none;
    }
  }

  &-message {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    margin-bottom: 1.6rem;

    &--sender {
      flex-direction: row-reverse;

      .chat-message {
        &__data {
          background: #f1f3f5;
          border-radius: 6px 6px 6px 0;
          margin-left: 0.8rem;
        }

        &__date {
          left: 3.2rem;
          right: auto;
        }
      }
    }

    &__photo {
      width: 2.4rem;
      min-width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      overflow: hidden;
      background: #103887;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      font-weight: 600;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__data {
      max-width: 48rem;
      background: $message;
      border-radius: 6px 6px 0 6px;
      padding: 2.4rem;
      margin-right: 0.8rem;
    }

    &__date {
      font-size: 1rem;
      line-height: 1.4rem;
      color: $form-def-placeholder-color;
      position: absolute;
      top: 100%;
      right: 3.2rem;
    }

    &__name {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-bottom: 0.8rem;
      color: $form-def-label-color;
    }

    &__text {
      font-size: 1.4rem;
      line-height: 150%;
      color: $text;
    }
  }

  &-footer {
    border-top: 2px solid $border;
    height: 9rem;
    position: relative;

    textarea {
      height: 100%;
      width: 100%;
      border: none;
      resize: none;
      padding: 3rem 9rem 1rem 4rem;
      font-size: 1.4rem;
      line-height: 150%;
      color: $text;
      font-family: $mainFont;

      &:focus {
        outline: none;
      }
    }

    &__send {
      border: none;
      background: none;
      position: absolute;
      right: 5rem;
      top: 3.2rem;
      z-index: 1;

      &--active {
        path {
          stroke: $primary;
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max){
	.chat-header{
		padding: 16px;
		padding-right: 50px;
		height: auto;
	}
	.chat-messages__wrapper{
		padding: 16px;
	}
	.chat-messages{
		height: auto;
		flex-grow: 1;
	}
	.chat-footer{
		textarea{
			padding: 18px 50px 22px 16px;
			font-size: 14px;
			line-height: 20px;

			&::placeholder{
				color: #A2A7BE;
			}
		}
	}
	.chat-footer__send{
		top: 18px;
		right: 18px;
	}
	.chat-message__photo{
		display: none;
	}
	.chat-message__data{
		margin: 0;
	}
	.chat-message__date{
		right: 0;
		top: calc(100% + 4px);
		font-size: 10px;
		line-height: 14px;
		color: #A1ADBB;
	}
	.chat-message{
		margin-bottom: 30px;
	}
	.chat-message--sender{
		+ .chat-message--sender:not(.chat-message--sender){
			margin-top: 40px;
		}
		.chat-message__data{
			margin: 0;
		}
		.chat-message__date{
			left: 0;
		}
	}
}
</style>
