import axios from 'axios'

const initialState = {
  companiesList: null,
  pagination: null,
  successCreate: false,
  errors: null,
}

const state = Object.assign({}, initialState)

const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  resetState(state) {
    for (let prop in state) {
      state[prop] = initialState[prop]
    }
  },
}

const actions = {
  getCompanies({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL +
          `/company?page=${data.page}&pageCount=${data.pageCount}${
            data.sortField ? '&sortField=' + data.sortField : ''
          }${data.sortBy ? '&sortBy=' + data.sortBy : ''}`,
        method: 'GET',
      })
        .then(resp => {
          commit('setData', {
            label: 'companiesList',
            data: resp.data.data.data,
          })
          commit('setData', {
            label: 'pagination',
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page,
            },
          })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/company/${data.id}`,
        method: 'GET',
      })
        .then(resp => {
          commit('setData', {
            label: 'professionData',
            data: resp.data.data,
          })
          resolve(resp)
        })
        .catch(err => {
          console.error(err.response)
          reject(err)
        })
    })
  },
  createCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_URL + '/company', data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  editCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_URL + '/company/' + data.id, data: data.data, method: 'PUT' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  deleteCompany(ctx, id) {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_URL + '/company/' + id, method: 'DELETE' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  blockCompany(ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_URL + '/company/' + data.id + '/status', data: data.data, method: 'PUT' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
