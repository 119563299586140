var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_vm.currentOption ? _c('div', {
    staticClass: "option"
  }, [_c('div', {
    staticClass: "option__close",
    on: {
      "click": _vm.closeOption
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-close.svg")
    }
  })]), _c(_vm.currentOption, {
    tag: "component"
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }