import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: () => import("@/views/Landing"),
    meta: {
      layout: "land-layout"
    }
  },
  {
    path: "/agreement",
    name: "Agreement",
    component: () => import("@/views/Agreement"),
    meta: {
      layout: "land-layout"
    }
  },
  {
    path: "/employees",
    name: "Employees",
    component: () => import("@/views/Employees"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/employees/:id",
    name: "Employee",
    component: () => import("@/views/Employee"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/employees/:id/steps/:projectId/:vacancyId",
    name: "Steps",
    component: () => import("@/views/Steps"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/new-employee",
    name: "NewEmployee",
    component: () => import("@/views/NewEmployee"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/new-project",
    name: "NewProject",
    component: () => import("@/views/NewProject"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/add-to-project",
    name: "AddToProject",
    component: () => import("@/views/AddToProject"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("@/views/Projects"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/projects/:id",
    name: "Project",
    component: () => import("@/views/Project"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/companies",
    name: "Companies",
    component: () => import("@/views/Companies"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/professions",
    name: "Professions",
    component: () => import("@/views/Professions"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/hire",
    name: "HireEmployee",
    component: () => import("@/views/HireEmployee"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/hire-for-project",
    name: "HireForProject",
    component: () => import("@/views/HireForProject.vue"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/tariffs",
    name: "Tariffs",
    component: () => import("@/views/Tariffs"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("@/views/Help"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/Search"),
    meta: {
      layout: "base-layout",
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "auth-layout"
    }
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () => import("@/views/ForgetPassword"),
    meta: {
      layout: "auth-layout"
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword"),
    meta: {
      layout: "auth-layout"
    }
  },
  {
    path: "/free-registration",
    name: "FreeRegistration",
    component: () => import("@/views/FreeRegistration"),
    meta: {
      layout: "auth-layout"
    }
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound"),
    meta: {
      layout: "auth-layout",
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !store.state.Auth.token
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
