import axios from 'axios'

const initialState = {
  professionsList: null,
  pagination: null,
  successCreate: false,
  successEdit: false,
  successRemove: false,
  professionData: null,
  offersPagination: null,
  projectsList: null,
}

const state = Object.assign({}, initialState)

const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  resetState(state) {
    for (let prop in state) {
      state[prop] = initialState[prop]
    }
  },
}

const actions = {
  getProfessions({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/specialities`,
        method: 'GET',
        params,
      })
        .then(resp => {
          commit('setData', {
            label: 'professionsList',
            data: resp.data.data.data,
          })
          commit('setData', {
            label: 'pagination',
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page,
            },
          })
          resolve(resp)
        })
        .catch(err => {
          console.error(err.response)
          reject(err)
        })
    })
  },
  getProfession({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/specialities/${id}`,
        method: 'GET',
      })
        .then(resp => {
          commit('setData', {
            label: 'professionData',
            data: resp.data.data,
          })
          resolve(resp)
        })
        .catch(err => {
          console.error(err.response)
          reject(err)
        })
    })
  },
  createProfession({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/specialities`,
        method: 'POST',
        data,
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.error(err.response)
          reject(err)
        })
    })
  },
  editProfession({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/specialities/${data.id}`,
        method: 'PUT',
        data: data.data,
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.error(err.response)
          reject(err)
        })
    })
  },
  deleteProfession({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + '/specialities/' + id,
        method: 'DELETE',
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.error(err.response)
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
