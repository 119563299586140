var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat"
  }, [_c('div', {
    staticClass: "chat-header"
  }, [_vm.currentChat && _vm.currentChat.type === 'user' ? _c('div', {
    staticClass: "chat-header__user"
  }, [_c('div', {
    staticClass: "chat-header__user-photo"
  }, [_vm.currentChat.image ? _c('img', {
    attrs: {
      "src": _vm.currentChat.image
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.currentChat.name ? _vm.currentChat.name.substring(0, 1) : '') + " ")])]), _c('div', {
    staticClass: "chat-header__user-info"
  }, [_c('div', {
    staticClass: "chat-header__user-name"
  }, [_vm._v(" " + _vm._s(_vm.currentChat.name) + " ")]), _c('div', {
    staticClass: "chat-header__user-status"
  }, [_vm._v(" Не в сети ")])])]) : _vm._e(), _vm.currentChat && _vm.currentChat.type === 'project' ? _c('div', {
    staticClass: "chat-header__group"
  }, [_vm._v(" ЧАТ проекта " + _vm._s(_vm.currentChat.name) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "chat-messages"
  }, [_c('infinite-loading', {
    attrs: {
      "direction": "top"
    },
    on: {
      "infinite": _vm.infiniteHandler
    }
  }, [_c('div', {
    staticClass: "chat-messages__notify",
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }, [_vm._v("Все сообщения загружены")]), _c('div', {
    staticClass: "chat-messages__notify",
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  }, [_vm._v("Нет сообщений")])]), _c('div', {
    staticClass: "chat-messages__wrapper"
  }, _vm._l(_vm.messages, function (mes, index) {
    return _c('div', {
      key: index,
      staticClass: "chat-message",
      class: {
        'chat-message--sender': mes.from_user_id !== _vm.currentUserId
      }
    }, [_c('div', {
      staticClass: "chat-message__data"
    }, [mes.name ? _c('div', {
      staticClass: "chat-message__name",
      domProps: {
        "textContent": _vm._s(mes.name)
      }
    }) : _vm._e(), _c('div', {
      staticClass: "chat-message__text",
      domProps: {
        "textContent": _vm._s(mes.text)
      }
    })]), _c('div', {
      staticClass: "chat-message__photo"
    }, [mes.image ? _c('img', {
      attrs: {
        "src": _vm.fixedImage(mes.image)
      }
    }) : mes.name ? _c('span', [_vm._v(" " + _vm._s(mes.name ? mes.name.substring(0, 1) : '') + " ")]) : _vm._e()]), _c('div', {
      staticClass: "chat-message__date"
    }, [_vm._v(" " + _vm._s(_vm.fixedDate(mes.created_at)) + " ")])]);
  }), 0)], 1), _c('div', {
    staticClass: "chat-footer"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newMessage,
      expression: "newMessage"
    }],
    attrs: {
      "rows": "1",
      "name": "message",
      "placeholder": "Введите текст сообщения..."
    },
    domProps: {
      "value": _vm.newMessage
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.sendMessage($event);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.newMessage = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "chat-footer__send",
    class: {
      'chat-footer__send--active': _vm.newMessage
    },
    attrs: {
      "disabled": !_vm.newMessage
    },
    on: {
      "click": _vm.sendMessage
    }
  }, [_c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 2L11 13",
      "stroke": "#A2A7BE",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M22 2L15 22L11 13L2 9L22 2Z",
      "stroke": "#A2A7BE",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }