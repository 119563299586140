import axios from "axios";

const initialState = {
  status: "",
  rememberMe: false,
  rememberMeStore: false,
  token: sessionStorage.getItem("token") || localStorage.getItem("token") || "",
  user: {},
  errors: null,
  userRole:
    sessionStorage.getItem("user-role") ||
    localStorage.getItem("user-role") ||
    null,
  currentCompanyOfUser: null,
  ready: false
};
const state = Object.assign({}, initialState);

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, { token, userRole }) {
    state.status = "success";
    state.token = token;
    state.userRole = userRole;
  },
  auth_error(state, err) {
    state.status = "error";
    state.errors = err;
  },
  logout(state) {
    state.status = "";
    state.token = "";
    state.userRole = null;
  },
  setData(state, data) {
    state[data.label] = data.data;
  },
  resetState(state) {
    for (let prop in state) {
      state[prop] = initialState[prop];
    }
  }
};

const actions = {
  login({ commit, dispatch, state }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: process.env.VUE_APP_API_URL + "/auth/login",
        data: user,
        method: "POST"
      })
        .then(resp => {
          const token = resp.data.data.token;
          const userRole = resp.data.data.user_role;
          if (userRole === "employee") {
            commit("auth_error", "У этого пользователя недостаточно прав");
            resolve(resp);
          } else {
            if (state.rememberMe) {
              localStorage.setItem("token", token);
              localStorage.setItem("user-role", userRole);
              localStorage.setItem("remember-me", true);
            } else {
              sessionStorage.setItem("token", token);
              sessionStorage.setItem("user-role", userRole);
              localStorage.removeItem("remember-me");
            }
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("auth_success", { userRole, token });
            dispatch("getUser");
            resolve(resp);
          }
        })
        .catch(err => {
          commit("auth_error", err.response.data.errors);
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      commit("logout");
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("user-role");
      sessionStorage.removeItem("user-role");
      resolve();
    });
  },
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/user/current",
        method: "GET"
      })
        .then(resp => {
          commit("setData", { label: "user", data: resp.data.data });
          if (resp.data.data.company) {
            commit("setData", {
              label: "currentCompanyOfUser",
              data: resp.data.data.company.id
            });
          }
          commit("setData", { label: "ready", data: true });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/auth/send-reset-password-code",
        data,
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          commit("auth_error", err.response.data.errors);
          reject(err);
        });
    });
  },
  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/auth/reset-password",
        data,
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          commit("auth_error", err.response.data.errors);
          reject(err);
        });
    });
  },
  changePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/auth/change-password/" + data.id,
        data: data.data,
        method: "PUT"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          commit("auth_error", err.response.data.errors);
          reject(err);
        });
    });
  },
  freeRegistration({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/managers",
        data,
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          commit("auth_error", err.response.data.errors);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
