var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-card"
  }, [_c('div', {
    staticClass: "company-card__name"
  }, [_vm._v(" " + _vm._s(_vm.currentCompany ? _vm.currentCompany.title : "Новая компания") + " ")]), _c('el-form', {
    ref: "form",
    staticClass: "company-card__form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm($event);
      }
    }
  }, [_c('div', {
    staticClass: "company-card__data"
  }, [_c('div', {
    staticClass: "company-card__form--left"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Наименование компании",
      "prop": "title"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Контактный телефон",
      "prop": "phone"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ИНН",
      "prop": "inn"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.inn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "inn", $$v);
      },
      expression: "form.inn"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Контактное лицо (ФИО)",
      "prop": "contact_fio"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.contact_fio,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contact_fio", $$v);
      },
      expression: "form.contact_fio"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "E-mail",
      "prop": "email"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1), !_vm.currentCompany ? _c('el-form-item', {
    attrs: {
      "label": "Пароль",
      "prop": "password"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.password"
    }
  })], 1) : _vm._e(), !_vm.currentCompany ? _c('el-form-item', {
    attrs: {
      "label": "Повторите пароль",
      "prop": "password_confirmation"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.form.password_confirmation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password_confirmation", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.password_confirmation"
    }
  })], 1) : _vm._e(), _vm.currentCompany && _vm.isChangingPassword ? _c('el-form-item', {
    attrs: {
      "label": "Новый пароль"
    }
  }, [_c('el-input', {
    ref: "input-password",
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.formPassword.password,
      callback: function callback($$v) {
        _vm.$set(_vm.formPassword, "password", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "formPassword.password"
    }
  }), _c('transition', {
    attrs: {
      "name": "el-zoom-in-top"
    }
  }, [_vm.errors.password ? _c('div', {
    staticClass: "el-form-item__error",
    domProps: {
      "textContent": _vm._s(_vm.errors.password)
    }
  }) : _vm._e()])], 1) : _vm._e(), _vm.currentCompany && _vm.isChangingPassword ? _c('el-form-item', {
    attrs: {
      "label": "Повторите пароль"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.formPassword.password_confirmation,
      callback: function callback($$v) {
        _vm.$set(_vm.formPassword, "password_confirmation", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "formPassword.password_confirmation"
    }
  }), _c('transition', {
    attrs: {
      "name": "el-zoom-in-top"
    }
  }, [_vm.errors.password_confirmation ? _c('div', {
    staticClass: "el-form-item__error",
    domProps: {
      "textContent": _vm._s(_vm.errors.password_confirmation)
    }
  }) : _vm._e()])], 1) : _vm._e(), _vm.currentCompany ? _c('div', {
    staticClass: "company-card__form-password-links"
  }, [!_vm.isChangingPassword ? _c('div', {
    staticClass: "company-card__form-password-links__confirm",
    on: {
      "click": function click($event) {
        _vm.isChangingPassword = true;
      }
    }
  }, [_vm._v(" Изменить пароль ")]) : _vm._e(), _vm.isChangingPassword ? _c('div', {
    staticClass: "company-card__form-password-links__confirm",
    on: {
      "click": _vm.changePassword
    }
  }, [_vm._v(" Сохранить пароль ")]) : _vm._e(), _vm.isChangingPassword ? _c('div', {
    staticClass: "company-card__form-password-links__cancel",
    on: {
      "click": function click($event) {
        _vm.isChangingPassword = false;
      }
    }
  }, [_vm._v(" Отмена ")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "company-card__form--right"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Адрес места нахождения",
      "prop": "address"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ОГРН/ОГРНИП",
      "prop": "ogrnOgrnip"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.ogrn_ogrnip,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ogrn_ogrnip", $$v);
      },
      expression: "form.ogrn_ogrnip"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "КПП",
      "prop": "kpp"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.kpp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kpp", $$v);
      },
      expression: "form.kpp"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Телефон контактного лица",
      "prop": "contact_phone"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contact_phone", $$v);
      },
      expression: "form.contact_phone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Описание профиля деятельности",
      "prop": "description"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "resize": "none",
      "rows": 6
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "company-card__form__footer",
    class: {
      'company-card__form__footer--end': !_vm.currentCompany
    }
  }, [_vm.currentCompany ? _c('div', {
    staticClass: "company-card__form__footer-options"
  }, [_c('div', {
    staticClass: "company-card__form__footer-options__item",
    on: {
      "click": _vm.toProject
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/icons/icon-to-project.svg"),
      "title": "К проектам"
    }
  })]), _vm.userRole === 'admin' ? _c('div', {
    staticClass: "company-card__form__footer-options__item",
    on: {
      "click": _vm.blockCompany
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/icons/icon-block.svg"),
      "title": this.currentCompany.status ? 'Разблокировать' : 'Заблокировать'
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "company-card__form__footer-options__item",
    on: {
      "click": _vm.removeCompany
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/icons/icon-delete.svg"),
      "title": "Удалить"
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "company-card__form__footer-actions"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancelChanges
    }
  }, [_vm._v(" Отменить ")]), _c('el-button', {
    attrs: {
      "type": "primary el-button--stretch",
      "native-type": "submit",
      "disabled": _vm.isDisabled
    }
  }, [_vm._v(" Сохранить изменения ")])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }