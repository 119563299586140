var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-layout"
  }, [_c('div', {
    staticClass: "auth-layout__frame"
  }, [_c('div', {
    staticClass: "auth-layout__logo"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Landing'
      }
    }
  }, [_c('div', {
    staticClass: "auth-logo"
  }, [_c('span', {
    staticClass: "auth-logo__title"
  }, [_vm._v("Smetter")])])])], 1), _vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }