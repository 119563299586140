import { render, staticRenderFns } from "./AddOptionBg.vue?vue&type=template&id=a9df0c0e&scoped=true&"
import script from "./AddOptionBg.vue?vue&type=script&lang=js&"
export * from "./AddOptionBg.vue?vue&type=script&lang=js&"
import style0 from "./AddOptionBg.vue?vue&type=style&index=0&id=a9df0c0e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9df0c0e",
  null
  
)

export default component.exports