import axios from 'axios'

const state = () => ({
  searchTextQuery: '',
  pagination: null,
})

const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
}

const actions = {
  getResults({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + '/search',
        method: 'GET',
        params,
      })
        .then(resp => {
          commit('setData', {
            label: 'pagination',
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page,
            },
          })
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
