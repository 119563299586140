import axios from "axios";

const initialState = {
  employeesList: null,
  pagination: null,
  employeeSteps: null,
  employeeData: null,
  employeeProjects: null,
  employeeProjectsPagination: null,
  employeePlan: null,
  employeePlanPagination: null,
  employeeSpecialities: null,
  employeeReport: null,
  employeeReportTotal: null,
  freeVacancies: null,
  freeVacanciesPagination: null,
  createEmployeeErrors: null,
  editEmployeeErrors: null,
  specialitiesList: null,
  projectsListAll: null,
  workPlanList: null,
  companiesList: null,
  vacanciesListAll: null,
  employeePlanDinnerMore: {}
};

const state = Object.assign({}, initialState);

const mutations = {
  setData(state, data) {
    state[data.label] = data.data;
  },
  resetState(state) {
    for (let prop in state) {
      state[prop] = initialState[prop];
    }
  },
  saveEmployeeDinnerMore(state, { id, data }) {
    state.employeePlanDinnerMore[id] = data.list;
    state.employeePlanDinnerMore = { ...state.employeePlanDinnerMore };
  }
};

const actions = {
  getEmployees({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/employee`,
        method: "GET",
        params
      })
        .then(resp => {
          commit("setData", {
            label: "employeesList",
            data: resp.data.data.data
          });
          commit("setData", {
            label: "pagination",
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page
            }
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getFreeVacancies({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL +
          `/employee/${data.employee_id}/free-vacancies`,
        method: "GET",
        params: data.params
      })
        .then(resp => {
          commit("setData", {
            label: "freeVacancies",
            data: resp.data.data.data
          });
          commit("setData", {
            label: "freeVacanciesPagination",
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page
            }
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/employee/${data.id}`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "employeeData",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  createEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/employee`,
        method: "POST",
        data
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          commit("setData", {
            label: "createEmployeeErrors",
            data: err.response.data.errors
          });
          reject(err);
        });
    });
  },
  editEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/employee/${data.id}`,
        method: "PUT",
        data: data.data
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          commit("setData", {
            label: "editEmployeeErrors",
            data: err.response.data.errors
          });
          reject(err);
        });
    });
  },
  removeEmployee({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/employee/" + id,
        method: "DELETE"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  blockEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/auth/switch-block/" + data.id,
        method: "PUT",
        data: data.data
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getProjectsByEmployeeId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/employee/${data.id}/projects`,
        method: "GET",
        params: data.params
      })
        .then(resp => {
          commit("setData", {
            label: "employeeProjects",
            data: resp.data.data.data
          });
          commit("setData", {
            label: "employeeProjectsPagination",
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page
            }
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getPlanByEmployeeId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/employee/${data.id}/plan`,
        method: "GET",
        params: data.params
      })
        .then(resp => {
          commit("setData", {
            label: "employeePlan",
            data: resp.data.data.data
          });
          commit("setData", {
            label: "employeePlanPagination",
            data: {
              totalItems: resp.data.data.total,
              totalPage: resp.data.data.last_page,
              currentPage: resp.data.data.current_page
            }
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  dismissEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL + `/vacancy/${data.id}/delete-employee`,
        data: data.data,
        method: "PUT"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getSpecialities({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/specialities/list",
        method: "GET",
        params
      })
        .then(resp => {
          commit("setData", {
            label: "specialitiesList",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getEmployeeSpecialities({ commit }, { employee_id }) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL + `/employee/${employee_id}/specialities`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "employeeSpecialities",
            data: resp.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  setEmployeeSpecialities({ commit }, { employee_id, specialities }) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL + `/employee/${employee_id}/specialities`,
        method: "PUT",
        data: { ids: specialities }
      })
        .then(resp => {
          commit("setData", {
            label: "employeeSpecialities",
            data: resp.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  downloadEmployeeReport({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/employee/" + data.id + "/export",
        params: data.data,
        method: "GET",
        responseType: "blob"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  getProjectsAll({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/project/list",
        method: "GET",
        params
      })
        .then(resp => {
          commit("setData", {
            label: "projectsListAll",
            data: resp.data.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getVacanciesAll({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL + `/employee/${id}/free-vacancies-list`,
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "vacanciesListAll",
            data: resp.data.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getWorkPlan({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/workstatus",
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "workPlanList",
            data: resp.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  uploadImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/image/upload",
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        data
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getCompaniesList({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + "/company/list",
        method: "GET"
      })
        .then(resp => {
          commit("setData", {
            label: "companiesList",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  setWorkedTime({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/worktime/${payload.id}`,
        method: "PUT",
        data: payload.data
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  setWorkedTimeInit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/worktime`,
        method: "POST",
        data: payload.data
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getEmployeeSteps({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/step`,
        method: "GET",
        params: data.params
      })
        .then(resp => {
          commit("setData", {
            label: "employeeSteps",
            data: resp.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getEmployeeReport({ commit }, { data, employee_id, total }) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL +
          `/users/${employee_id}/activity/statistics`,
        method: "GET",
        params: data
      })
        .then(resp => {
          commit("setData", {
            label: "employeeReport",
            data: { data: resp.data.data, total: total }
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getEmployeeReportTotal({ commit }, { data, employee_id }) {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_URL +
          `/users/${employee_id}/activity/statistics/total`,
        method: "GET",
        params: data
      })
        .then(resp => {
          commit("setData", {
            label: "employeeReportTotal",
            data: resp.data.data
          });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  postEmployeeReportDinner({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/users/dinners`,
        method: "POST",
        data: data
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  getEmployeePlanDinnerMore({ commit }, { id_employee, id, data }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/employee/${id_employee}/dinners`,
        method: "GET",
        params: data
      })
        .then(resp => {
          console.log("resp, ", resp);
          commit("saveEmployeeDinnerMore", { data: resp.data.data, id: id });
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  },
  putEmployeeReportChangeAppStatus({ commit }, { id, status }) {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_API_URL + `/activity-times/${id}`,
        method: "PUT",
        data: { is_active: status }
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
