<template>
  <div v-if="userData" class="user-card">
    <a
      v-if="userData.role !== 'admin' && count > 0"
      href="#"
      class="user-card__notice"
      title="Уведомления"
      @click.prevent="openChat"
    >
      <span v-if="count > 0" class="user-card__notice-count" v-text="count" />
    </a>
    <div class="user-card__info" :class="{ 'user-card_active': isMenu }">
      <transition name="el-fade-in">
        <div class="user-card__drop" v-if="isMenu">
          <div class="user-card__drop-item">
            <span
              @click="changePassword"
              class="user-card__drop-link user-card__drop-link_change-pass"
            >
              Изменить пароль
            </span>
          </div>
          <div v-if="currentTariff" class="user-card__drop-item">
            <router-link
              to="/tariffs"
              class="user-card__drop-link user-card__drop-link_tariff"
            >
              Тариф {{ currentTariff.name }}
            </router-link>
          </div>
          <div class="user-card__drop-item">
            <a
              href="#"
              @click.prevent="logout"
              class="user-card__drop-link user-card__drop-link_logout"
            >
              Выйти из акаунта
            </a>
          </div>
          <div v-if="userRole !== 'admin'" class="user-card__drop-item">
            <div
              class="user-card__drop-link user-card__drop-link_delete"
              @click="removeCompany"
            >
              Удалить компанию
            </div>
          </div>
        </div>
      </transition>
      <a
        href="#"
        class="user-card__control"
        :title="userData.name"
        @click.prevent="isMenu = !isMenu"
      >
        <div class="user-card__avatar">
          <img
            class="user-card__avatar-img"
            src="@/assets/images/avatar.jpg"
            :alt="userData.name"
          />
        </div>
        <div class="user-card__person">
          <div class="user-card__login">{{ userData.name }}</div>
          <div v-if="userData.company" class="user-card__company">
            {{ userData.company.title }}
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCard",
  data() {
    return {
      isMenu: false
    };
  },
  mounted() {
    document.addEventListener(
      "click",
      e => {
        if (this.isMenu && !e.target.closest(".user-card__info")) {
          this.isMenu = false;
        }
      },
      true
    );
    document.addEventListener("keyup", e => {
      if (this.isMenu && e.key === "Escape") {
        this.isMenu = false;
      }
    });
  },
  computed: {
    userData() {
      return this.$store.state.Auth.user;
    },
    userRole() {
      return this.$store.state.Auth.userRole;
    },
    count() {
      return this.$store.getters["Chat/globalCount"];
    },
    allMessages() {
      return this.$store.getters["Chat/allMessages"];
    },
    currentTariff() {
      return this.$store.getters["Tariffs/currentTariff"];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("Auth/logout").then(() => {
        this.$store.commit("Auth/resetState");
        this.$store.commit("Company/resetState");
        this.$store.commit("Employee/resetState");
        this.$store.commit("Professions/resetState");
        this.$store.commit("Projects/resetState");
        this.$store.commit("Tariffs/resetState");
        this.$router.push({ name: "Login" });
      });
    },
    openChat() {
      const unreadChat = this.allMessages.find(el => el.count);
      if (unreadChat) {
        this.$store.commit("Chat/setData", {
          label: "currentChat",
          data: { type: unreadChat.type, id: unreadChat.id }
        });
        this.$store.commit("Option/setData", {
          label: "currentOption",
          data: "Chat"
        });
      } else {
        this.$store.dispatch("Chat/getChats");
      }
    },
    changePassword() {
      if (this.$store.state.Auth.currentCompanyOfUser) {
        const company = this.$store.state.Auth.user.company;
        company.isChangingPassword = true;
        this.$store.commit("Option/setData", {
          label: "detailCompany",
          data: company
        });
        this.$store.commit("Option/setData", {
          label: "currentOption",
          data: "CompanyCard"
        });
      } else {
        this.$router.push({ name: "ForgetPassword" });
      }
    },
    removeCompany() {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-delete-red.svg")}">
        <div>
          <div class="remove-message__title">
            Удаление компании
          </div>
          <div class="remove-message__text">
            Вы уверены, что хотите удалить компанию?
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Удалить",
          cancelButtonText: "Отменить",
          showClose: false
        }
      ).then(() => {
        this.$store
          .dispatch("Company/deleteCompany", this.userData.company.id)
          .then(() => {
            this.$notify({
              title: "Успех",
              message: "Компания удалена",
              type: "success"
            });
            this.logout();
          })
          .catch(err => {
            console.log(err);
            this.$notify({
              title: "Ошибка",
              message: "Ошибка, попробуйте еще раз",
              type: "error"
            });
          });
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.user-card {
  display: flex;
  align-items: center;

  &__notice {
    position: relative;
    height: 2.4rem;
    width: 2.4rem;
    margin-right: 2.4rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.0655 15.8595C19.686 14.7074 19.4921 13.5024 19.491 12.2895V9.29249C19.5186 7.39347 18.8409 5.5517 17.589 4.12349C16.3414 2.69303 14.6098 1.77328 12.726 1.54049C11.685 1.43249 10.626 1.54049 9.621 1.88099C8.616 2.20349 7.701 2.74199 6.93 3.46049C6.15515 4.16046 5.53757 5.0168 5.118 5.97299C4.69635 6.92881 4.47626 7.9613 4.4715 9.00599V12.306C4.4693 13.5139 4.27539 14.7139 3.897 15.861L3 18.5145L3.7185 19.5015H8.976C8.976 20.2905 9.2985 21.0615 9.855 21.6195C10.41 22.1745 11.1825 22.4985 11.973 22.4985C12.762 22.4985 13.533 22.176 14.0895 21.6195C14.646 21.063 14.97 20.2905 14.97 19.5015H20.2275L20.9445 18.5145L20.0655 15.8595ZM13.0305 20.58C12.8925 20.7208 12.728 20.8328 12.5465 20.9098C12.365 20.9868 12.1701 21.0271 11.973 21.0285C11.7755 21.0271 11.5803 20.9867 11.3985 20.9094C11.2167 20.8322 11.0521 20.7197 10.914 20.5785C10.773 20.4406 10.6606 20.2762 10.5834 20.0947C10.5061 19.9132 10.4656 19.7182 10.464 19.521H13.4625C13.4688 19.7178 13.4337 19.9137 13.3595 20.0961C13.2854 20.2785 13.1738 20.4434 13.032 20.58H13.0305ZM4.758 18.015L5.3145 16.344C5.74847 15.0422 5.97277 13.6797 5.979 12.3075V9.00599C5.979 8.16149 6.159 7.33649 6.4995 6.58199C6.84 5.81099 7.3245 5.12849 7.953 4.57199C8.5815 3.99899 9.318 3.56699 10.107 3.31649C10.914 3.04649 11.757 2.95649 12.582 3.04649C14.094 3.24531 15.4803 3.99264 16.4775 5.14649C17.475 6.30178 18.0112 7.78443 17.9835 9.31049V12.3255C17.9835 13.689 18.1995 15.0525 18.648 16.362L19.2045 18.0315H4.7595V18.0135L4.758 18.015Z' fill='%2331333B'/%3E%3C/svg%3E%0A");
    cursor: pointer;
    transition: opacity $transition-duration;

    &:hover {
      opacity: 0.8;
    }

    &-count {
      position: absolute;
      right: -1px;
      top: -2px;
      height: 1.3rem;
      min-width: 1.3rem;
      font-size: 0.9rem;
      line-height: 1.1rem;
      text-align: center;
      color: #fff;
      padding: 0.1rem 0.2rem 0 0.3rem;
      border-radius: 50%;
      background-color: #dd4a64;
    }
  }

  &__info {
    position: relative;
  }

  &__control {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    transition: opacity $transition-duration;

    &:hover {
      opacity: 0.8;
    }
  }

  &__avatar {
    position: relative;
    overflow: hidden;
    height: 4.2rem;
    width: 4.2rem;
    border-radius: 4.2rem;
    margin-right: 8px;
  }

  &__avatar-img {
    max-width: none;
    max-height: none;
    width: 4.2rem;
    height: 4.2rem;
    object-fit: cover;
  }

  &__person {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 1.8rem;
    padding-right: 2rem;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 1px;
      top: 0.7rem;
      width: 10px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%2331333B' stroke-linecap='round'/%3E%3C/svg%3E");
      transition: transform $transition-duration;
    }

    .user-card_active & {
      &::after {
        transform: rotate(0.5turn);
      }
    }
  }

  &__login {
    @include truncate(18rem);

    width: 100%;
    position: relative;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #31333b;
  }

  &__company {
    @include truncate(18rem);

    width: 100%;
    font-size: 1rem;
    line-height: 1.4rem;
    color: #a2a7be;
  }

  &__drop {
    position: absolute;
    top: -0.8rem;
    right: -2.4rem;
    width: calc(100% + 2.4rem * 2);
    min-width: 20.9rem;
    padding: 6.7rem 2.4rem 1.5rem;
    background: #fff;
    border-radius: 2px;
  }

  &__drop-item {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__drop-link {
    display: block;
    position: relative;
    padding-left: 2.8rem;
    text-decoration: none;
    color: #31333b;
    -webkit-transition: opacity $transition-duration;
    transition: opacity $transition-duration;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: transform $transition-duration;
    }

    &_tariff {
      &::before {
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 267 267' %3E%3Cg%3E%3Cpath d='M133.333 12.5c66.69 0 120.834 54.144 120.834 120.833 0 66.69-54.144 120.834-120.834 120.834-66.689 0-120.833-54.144-120.833-120.834C12.5 66.644 66.644 12.5 133.333 12.5zm0 16.667c-57.491 0-104.166 46.675-104.166 104.166S75.842 237.5 133.333 237.5 237.5 190.824 237.5 133.333 190.824 29.167 133.333 29.167z' fill='%23000000' /%3E%3Cpath d='M87.5 104.167c0-20.711 16.789-37.5 37.5-37.5h50v16.666h-50c-11.506 0-20.833 9.328-20.833 20.834m0 0A20.833 20.833 0 0 0 125 125h20.842a37.491 37.491 0 0 1 37.491 37.492v.008c0 20.711-16.789 37.5-37.5 37.5h-50v-16.667h50.001c11.505 0 20.833-9.327 20.833-20.833v-.008a20.826 20.826 0 0 0-20.825-20.825H125a37.498 37.498 0 0 1-37.5-37.5' fill='%23000000' /%3E%3Cpath d='M125 50h16.667v166.667H125z' fill='%23000000' /%3E%3C/g%3E%3C/svg%3E");
      }
    }

    &_change-pass {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1917 10.8165C16.225 10.5499 16.25 10.2832 16.25 9.99988C16.25 9.71654 16.225 9.44988 16.1917 9.18321L17.95 7.80821C18.1083 7.68321 18.15 7.45821 18.05 7.27488L16.3833 4.39154C16.2833 4.20821 16.0583 4.14154 15.875 4.20821L13.8 5.04154C13.3667 4.70821 12.9 4.43321 12.3917 4.22488L12.075 2.01654C12.0612 1.91849 12.0121 1.82884 11.937 1.76441C11.8618 1.69997 11.7657 1.66517 11.6667 1.66654H8.33334C8.12501 1.66654 7.95001 1.81654 7.92501 2.01654L7.60834 4.22488C7.10001 4.43321 6.63334 4.71654 6.20001 5.04154L4.12501 4.20821C3.93334 4.13321 3.71667 4.20821 3.61667 4.39154L1.95001 7.27488C1.84167 7.45821 1.89167 7.68321 2.05001 7.80821L3.80834 9.18321C3.77501 9.44988 3.75001 9.72488 3.75001 9.99988C3.75001 10.2749 3.77501 10.5499 3.80834 10.8165L2.05001 12.1915C1.89167 12.3165 1.85001 12.5415 1.95001 12.7249L3.61667 15.6082C3.71667 15.7915 3.94167 15.8582 4.12501 15.7915L6.20001 14.9582C6.63334 15.2915 7.10001 15.5665 7.60834 15.7749L7.92501 17.9832C7.95001 18.1832 8.12501 18.3332 8.33334 18.3332H11.6667C11.875 18.3332 12.05 18.1832 12.075 17.9832L12.3917 15.7749C12.9 15.5665 13.3667 15.2832 13.8 14.9582L15.875 15.7915C16.0667 15.8665 16.2833 15.7915 16.3833 15.6082L18.05 12.7249C18.15 12.5415 18.1083 12.3165 17.95 12.1915L16.1917 10.8165ZM10 12.9165C8.39167 12.9165 7.08334 11.6082 7.08334 9.99988C7.08334 8.39154 8.39167 7.08321 10 7.08321C11.6083 7.08321 12.9167 8.39154 12.9167 9.99988C12.9167 11.6082 11.6083 12.9165 10 12.9165Z' fill='%2331333B'/%3E%3C/svg%3E%0A");
      }
    }

    &_logout {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 9.99992C9.34095 9.99992 10.8333 8.50753 10.8333 6.66659C10.8333 4.82564 9.34095 3.33325 7.5 3.33325C5.65905 3.33325 4.16667 4.82564 4.16667 6.66659C4.16667 8.50753 5.65905 9.99992 7.5 9.99992Z' fill='%2331333B'/%3E%3Cpath d='M0.833334 14.1666C0.833334 11.9499 5.275 10.8333 7.5 10.8333C8.69793 10.8333 10.5384 11.1569 11.9823 11.8017C12.2241 11.9097 12.2825 12.2178 12.1455 12.4444C11.8416 12.947 11.6667 13.5364 11.6667 14.1666C11.6667 14.9245 11.9196 15.6233 12.3457 16.1833C12.4852 16.3666 12.3644 16.6666 12.1341 16.6666H0.833334V14.1666Z' fill='%2331333B'/%3E%3Cpath d='M18.3333 14.1666L16.4103 16.2499V14.6527H13.3333V13.6805H16.4103V12.0833L18.3333 14.1666Z' fill='%2331333B'/%3E%3C/svg%3E%0A");
      }
    }

    &_delete {
      &::before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.625 2.875H5.5C5.56875 2.875 5.625 2.81875 5.625 2.75V2.875H10.375V2.75C10.375 2.81875 10.4312 2.875 10.5 2.875H10.375V4H11.5V2.75C11.5 2.19844 11.0516 1.75 10.5 1.75H5.5C4.94844 1.75 4.5 2.19844 4.5 2.75V4H5.625V2.875ZM13.5 4H2.5C2.22344 4 2 4.22344 2 4.5V5C2 5.06875 2.05625 5.125 2.125 5.125H3.06875L3.45469 13.2969C3.47969 13.8297 3.92031 14.25 4.45312 14.25H11.5469C12.0813 14.25 12.5203 13.8313 12.5453 13.2969L12.9312 5.125H13.875C13.9438 5.125 14 5.06875 14 5V4.5C14 4.22344 13.7766 4 13.5 4ZM11.4266 13.125H4.57344L4.19531 5.125H11.8047L11.4266 13.125Z' fill='%2331333B'/%3e%3c/svg%3e ");
      }
    }
  }
}

@media (max-width: $screen-lg-max) {
  .user-card__drop {
    right: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (max-width: $screen-2xs-max) {
  .user-card__avatar {
    margin-right: 0;
  }
  .user-card__person {
    display: none;
  }
  .user-card__notice {
    margin-right: 19px;
  }
}
</style>
