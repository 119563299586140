<template>
  <div class="company-card">
    <div class="company-card__name">
      {{ currentCompany ? currentCompany.title : "Новая компания" }}
    </div>
    <el-form
      class="company-card__form"
      ref="form"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
      @submit.native.prevent="submitForm"
    >
      <div class="company-card__data">
        <div class="company-card__form--left">
          <el-form-item label="Наименование компании" prop="title">
            <el-input v-model="form.title" type="text" />
          </el-form-item>
          <el-form-item label="Контактный телефон" prop="phone">
            <el-input v-model="form.phone" type="text" />
          </el-form-item>
          <el-form-item label="ИНН" prop="inn">
            <el-input v-model="form.inn" type="text" />
          </el-form-item>
          <el-form-item label="Контактное лицо (ФИО)" prop="contact_fio">
            <el-input v-model="form.contact_fio" type="text" />
          </el-form-item>
          <el-form-item label="E-mail" prop="email">
            <el-input v-model.trim="form.email" type="text" />
          </el-form-item>
          <el-form-item v-if="!currentCompany" label="Пароль" prop="password">
            <el-input
              v-model.trim="form.password"
              type="password"
              show-password
            />
          </el-form-item>
          <el-form-item
            v-if="!currentCompany"
            label="Повторите пароль"
            prop="password_confirmation"
          >
            <el-input
              v-model.trim="form.password_confirmation"
              type="password"
              show-password
            />
          </el-form-item>
          <el-form-item
            v-if="currentCompany && isChangingPassword"
            label="Новый пароль"
          >
            <el-input
              ref="input-password"
              v-model.trim="formPassword.password"
              type="password"
              show-password
            />
            <transition name="el-zoom-in-top">
              <div
                v-if="errors.password"
                class="el-form-item__error"
                v-text="errors.password"
              />
            </transition>
          </el-form-item>
          <el-form-item
            v-if="currentCompany && isChangingPassword"
            label="Повторите пароль"
          >
            <el-input
              v-model.trim="formPassword.password_confirmation"
              type="password"
              show-password
            />
            <transition name="el-zoom-in-top">
              <div
                v-if="errors.password_confirmation"
                class="el-form-item__error"
                v-text="errors.password_confirmation"
              />
            </transition>
          </el-form-item>
          <div v-if="currentCompany" class="company-card__form-password-links">
            <div
              v-if="!isChangingPassword"
              class="company-card__form-password-links__confirm"
              @click="isChangingPassword = true"
            >
              Изменить пароль
            </div>
            <div
              v-if="isChangingPassword"
              class="company-card__form-password-links__confirm"
              @click="changePassword"
            >
              Сохранить пароль
            </div>
            <div
              v-if="isChangingPassword"
              class="company-card__form-password-links__cancel"
              @click="isChangingPassword = false"
            >
              Отмена
            </div>
          </div>
        </div>
        <div class="company-card__form--right">
          <el-form-item label="Адрес места нахождения" prop="address">
            <el-input v-model="form.address" type="text" />
          </el-form-item>
          <el-form-item label="ОГРН/ОГРНИП" prop="ogrnOgrnip">
            <el-input v-model="form.ogrn_ogrnip" type="text" />
          </el-form-item>
          <el-form-item label="КПП" prop="kpp">
            <el-input v-model="form.kpp" type="text" />
          </el-form-item>
          <el-form-item label="Телефон контактного лица" prop="contact_phone">
            <el-input v-model="form.contact_phone" type="text" />
          </el-form-item>
          <el-form-item
            label="Описание профиля деятельности"
            prop="description"
          >
            <el-input
              v-model="form.description"
              type="textarea"
              resize="none"
              :rows="6"
            />
          </el-form-item>
        </div>
      </div>
      <div
        class="company-card__form__footer"
        :class="{ 'company-card__form__footer--end': !currentCompany }"
      >
        <div v-if="currentCompany" class="company-card__form__footer-options">
          <div
            class="company-card__form__footer-options__item"
            @click="toProject"
          >
            <img
              src="../../assets/icons/icon-to-project.svg"
              title="К проектам"
            />
          </div>
          <div
            v-if="userRole === 'admin'"
            class="company-card__form__footer-options__item"
            @click="blockCompany"
          >
            <img
              src="../../assets/icons/icon-block.svg"
              :title="
                this.currentCompany.status ? 'Разблокировать' : 'Заблокировать'
              "
            />
          </div>
          <div
            class="company-card__form__footer-options__item"
            @click="removeCompany"
          >
            <img src="../../assets/icons/icon-delete.svg" title="Удалить" />
          </div>
        </div>
        <div class="company-card__form__footer-actions">
          <el-button @click="cancelChanges">
            Отменить
          </el-button>
          <el-button
            type="primary el-button--stretch"
            native-type="submit"
            :disabled="isDisabled"
          >
            Сохранить изменения
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { nextTick } from "process";

export default {
  name: "CompanyCard",
  data() {
    const validatePassword2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите пароль еще раз"));
      } else if (value !== this.form.password) {
        callback(new Error("Пароли не совпадают"));
      } else {
        callback();
      }
    };
    const checkCompanyErrors = (rule, value, callback) => {
      if (this.companyErrors && this.companyErrors[rule.field]) {
        callback(new Error(this.companyErrors[rule.field][0]));
      } else {
        callback();
      }
    };
    return {
      isChangingPassword: false,
      isDisabled: false,
      form: {
        title: "",
        phone: "",
        inn: "",
        contact_fio: "",
        email: "",
        password: "",
        password_confirmation: "",
        address: "",
        ogrn_ogrnip: "",
        kpp: "",
        contact_phone: "",
        description: ""
      },
      formPassword: {
        password: "",
        password_confirmation: "",
        send_email: true
      },
      errors: {
        password: "",
        password_confirmation: ""
      },
      rules: {
        title: [
          {
            required: true,
            message: "Обязательное поле",
            trigger: ["blur", "change"]
          }
        ],
        phone: [
          {
            required: true,
            message: "Обязательное поле",
            trigger: ["blur", "change"]
          },
          {
            pattern: /^[+]{1}[0-9]{7,20}$/,
            message: "Номер должен состоять из цифр и начинаться с +",
            trigger: ["blur", "change"]
          },
          { validator: checkCompanyErrors, trigger: ["blur", "change"] }
        ],
        email: [
          { type: "email", message: "Некорректный email", trigger: ["blur"] },
          { validator: checkCompanyErrors, trigger: ["blur", "change"] }
        ],
        contact_fio: [
          {
            required: true,
            message: "Обязательное поле",
            trigger: ["blur", "change"]
          }
        ],
        address: [
          {
            required: true,
            message: "Обязательное поле",
            trigger: ["blur", "change"]
          }
        ],
        contact_phone: [
          {
            required: true,
            message: "Обязательное поле",
            trigger: ["blur", "change"]
          },
          {
            pattern: /^[+]{1}[0-9]{7,20}$/,
            message: "Номер должен состоять из цифр и начинаться с +",
            trigger: ["blur", "change"]
          },
          { validator: checkCompanyErrors, trigger: ["blur", "change"] }
        ],
        password: [
          {
            required: true,
            message: "Обязательное поле",
            trigger: ["blur", "change"]
          }
        ],
        password_confirmation: [
          {
            required: true,
            message: "Обязательное поле",
            trigger: ["blur", "change"]
          },
          { validator: validatePassword2, trigger: ["blur", "change"] }
        ]
      }
    };
  },
  computed: {
    currentCompany() {
      return this.$store.state.Option.detailCompany;
    },
    userRole() {
      return this.$store.state.Auth.userRole;
    },
    companyErrors() {
      return this.$store.state.Company.errors;
    }
  },
  watch: {
    formPassword: {
      deep: true,
      handler() {
        this.errors.password = "";
        this.errors.password_confirmation = "";
      }
    },
    form: {
      deep: true,
      handler() {
        this.$store.commit("Company/setData", { label: "errors", data: null });
      }
    }
  },
  mounted() {
    if (this.currentCompany) {
      for (const key in this.form) {
        this.form[key] = this.currentCompany[key];
      }
      if (this.currentCompany.manager) {
        this.form.phone = this.currentCompany.manager.phone;
        this.form.email = this.currentCompany.manager.email;
      } else {
        this.form.phone = this.$store.state.Auth.user.phone;
        this.form.email = this.$store.state.Auth.user.email;
      }
      this.isChangingPassword = this.currentCompany.isChangingPassword;
    }
    nextTick(() => {
      this.$refs.form.clearValidate();

      if (this.isChangingPassword) {
        this.$refs["input-password"]?.focus();
      }
    });
  },
  methods: {
    cancelChanges() {
      this.$store.commit("Option/closeOption");
    },
    submitForm() {
      this.isDisabled = true;
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.currentCompany ? this.editCompany() : this.createCompany();
        } else {
          this.isDisabled = false;
          return false;
        }
      });
    },
    createCompany() {
      const data = this.form;
      this.$store
        .dispatch("Company/createCompany", data)
        .then(() => {
          this.$notify({
            title: "Успех",
            message: "Компания создана",
            type: "success"
          });
          this.$store.commit("Company/setData", {
            label: "successCreate",
            data: true
          });
          for (const key in this.form) {
            this.form[key] = "";
          }
          this.isDisabled = false;
          this.$store.commit("Option/closeOption");
        })
        .catch(err => {
          console.error(err.response);
          this.isDisabled = false;
          this.$refs["form"].validate(() => {});
        });
    },
    editCompany() {
      const data = {};
      for (const key in this.form) {
        if (key !== "password" || key !== "password_confirmation") {
          data[key] = this.form[key];
        }
      }
      this.$store
        .dispatch("Company/editCompany", { data, id: this.currentCompany.id })
        .then(() => {
          this.$notify({
            title: "Ошибка",
            message: "Изменения сохранены",
            type: "success"
          });
          this.$store.commit("Company/setData", {
            label: "successCreate",
            data: true
          });
          for (const key in this.form) {
            this.form[key] = "";
          }
          this.isDisabled = false;
          this.$store.commit("Option/closeOption");
        })
        .catch(err => {
          console.error(err.response);
          this.isDisabled = false;
          this.$refs["form"].validate(() => {});
        });
    },
    blockCompany() {
      const data = { is_blocked: this.currentCompany.status === 0 ? 1 : 0 };
      this.$store
        .dispatch("Company/blockCompany", { id: this.currentCompany.id, data })
        .then(() => {
          this.$notify({
            title: "Успех",
            message: this.currentCompany.status
              ? "Компания разблокирована"
              : "Компания заблокирована",
            type: "success"
          });
        })
        .catch(err => {
          console.log(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    toProject() {
      this.$router.push({
        path: "/projects?company=" + this.currentCompany.id
      });
    },
    logout() {
      this.$store.dispatch("Auth/logout").then(() => {
        this.$store.commit("Auth/resetState");
        this.$store.commit("Company/resetState");
        this.$store.commit("Employee/resetState");
        this.$store.commit("Professions/resetState");
        this.$store.commit("Projects/resetState");
        this.$store.commit("Tariffs/resetState");
        this.$router.push({ name: "Login" });
      });
    },
    removeCompany() {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-delete-red.svg")}">
        <div>
          <div class="remove-message__title">
            Удаление компании
          </div>
          <div class="remove-message__text">
            Вы уверены, что хотите удалить компанию?
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Удалить",
          cancelButtonText: "Отменить",
          showClose: false
        }
      ).then(() => {
        this.$store
          .dispatch("Company/deleteCompany", this.currentCompany.id)
          .then(() => {
            this.getCompanies();
            this.$notify({
              title: "Успех",
              message: "Компания удалена",
              type: "success"
            });
            if (this.userRole !== "admin") {
              this.logout();
            }
          })
          .catch(err => {
            console.log(err);
            this.$notify({
              title: "Ошибка",
              message: "Ошибка, попробуйте еще раз",
              type: "error"
            });
          });
      });
    },
    changePassword() {
      if (!this.formPassword.password) {
        this.errors.password = "Обязательное поле";
        return false;
      }
      if (!this.formPassword.password_confirmation) {
        this.errors.password_confirmation = "Обязательное поле";
        return false;
      } else if (
        this.formPassword.password_confirmation !== this.formPassword.password
      ) {
        this.errors.password_confirmation = "Поля не совпадают";
        return false;
      }
      const id = this.currentCompany.user_id;
      const data = this.formPassword;
      this.$store
        .dispatch("Auth/changePassword", { data, id })
        .then(() => {
          this.$notify({
            title: "Успех",
            message: "Пароль изменен",
            type: "success"
          });
          this.formPassword.password = "";
          this.formPassword.password_confirmation = "";
          this.isChangingPassword = false;
        })
        .catch(err => {
          console.log(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.company-card {
  padding-top: 4.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__name {
    padding-left: 4rem;
    padding-right: 8rem;
    margin-bottom: 4rem;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.3rem;
    color: $text;
    word-break: break-all;
  }

  &__data {
    max-width: 78.4rem;
    display: flex;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0;
    overflow-y: auto;

    .el-form-item {
      width: 34rem;
      max-width: 100%;
      &.is-required {
        &::before {
          content: "*";
          color: #f56c6c;
          margin-right: 4px;
          position: absolute;
        }
        &::v-deep .el-form-item__label {
          margin-left: 14px;
        }
      }
    }

    &-password-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2rem;
      line-height: 1.6rem;

      &__confirm {
        color: $primary;
        text-decoration: underline;
        cursor: pointer;
      }

      &__cancel {
        color: $error;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &--left {
      padding: 0 1.2rem 0 4rem;
    }

    &--right {
      padding: 0 4rem 0 1.2rem;
    }

    &__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 2px solid $border;
      padding: 2.4rem 4rem;

      &--end {
        justify-content: flex-end;
      }

      &-options {
        display: flex;
        align-items: center;

        &__item {
          margin-right: 2.6rem;
          cursor: pointer;
          transition: 0.3s ease-out;

          &:hover {
            opacity: 0.6;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &-actions {
        display: flex;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .company-card {
    padding-top: 24px;
  }
  .company-card__name {
    padding-bottom: 26px;
    padding-left: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #e3e5ec;
  }
  .company-card__data {
    flex-direction: column;
  }
  .company-card__form {
    overflow-y: auto;
    display: block;

    .el-form-item {
      width: 100%;
      position: relative;
    }

    .el-form-item__label {
      font-size: 14px;
      line-height: 20px;
      color: #676973;
    }
  }
  .company-card__form--left {
    padding: 16px;
    padding-bottom: 0;
  }
  .company-card__form--right {
    padding: 16px;
  }
  .company-card__form-password-links {
    justify-content: flex-end;
    font-size: 14px;
    line-height: 20px;
  }
  .company-card__form__footer {
    border-top: none;
    padding: 16px;
    padding-bottom: 32px;
  }
  .company-card__form-password-links__confirm {
    margin-right: 15px;
  }
}

@media (max-width: $screen-3xs-max) {
  .company-card__form__footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .company-card__form__footer-options {
    margin-bottom: 32px;
  }
  .company-card__form__footer-options__item {
    display: flex;
  }
  .company-card__form__footer-actions {
    flex-direction: column;
    align-items: center;
    width: 100%;

    .el-button--primary {
      order: -10;
      width: 100%;
    }

    .el-button {
      margin: 0;
    }

    .el-button--default {
      margin-top: 16px;
      padding: 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }
}
</style>
