<template>
  <div class="auth-layout">
    <div class="auth-layout__frame">
      <div class="auth-layout__logo">
        <router-link :to="{name: 'Landing'}">
        <div class="auth-logo">
          <span class="auth-logo__title">Smetter</span>
        </div>
      </router-link>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.auth-layout {
  background-color: #113987;
  background-position: center;
  background-repeat: no-repeat;
  //background-size: auto, cover, auto;
  //background-image:
  //    url("../assets/images/bg-icons.png"),
  //    url("../assets/images/bg-auth.svg"),
  //    linear-gradient(0deg, #0e0d57 -5.32%, #113987 100%);
  background-image: linear-gradient(0deg, #0e0d57 -5.32%, #113987 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 3rem;

  @include below($sm) {
    padding: 4rem 16px;
  }

  &__frame {
    width: 46.4rem;
    max-width: 100%;
    padding: 8rem 6.4rem;
    background: #fefefe;
    box-shadow: 4px 8px 32px rgba(66, 28, 117, 0.04);
    border-radius: 6px;

    @include below($sm) {
      padding: 40px 20px;
    }
  }

  &__logo {
    width: 12.8rem;
    max-width: 100%;
    margin: 0 auto 4.8rem;

    @include below($sm) {
			margin-bottom: 32px;
		}
  }
}

.auth-logo {
  position: relative;
  background: url(../assets/images/logo.svg) no-repeat;
  background-size: contain;

  &::before {
    content: "";
    display: block;
    pointer-events: none;

    @include ratio(128, 30);
  }

  &__title {
    @include visibility-hide;
  }
}
</style>
