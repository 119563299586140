export const getDayOfWeek = date => {
  let dayOfWeek = "";
  if (date) {
    const day = new Date(date);
    const options = {
      weekday: "long"
    };
    dayOfWeek = day?.toLocaleString("ru-RU", options);
  }

  return dayOfWeek;
};

export const getHoursAndMinutes = totalSeconds => {
  if (!totalSeconds) {
    return 0;
  }

  let hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.ceil((totalSeconds % 3600) / 60);

  if (minutes === 60) {
    hours++;
  }

  return `${hours ? hours + "ч" : ""} ${
    minutes && minutes !== 60 ? minutes + "м" : ""
  }`;
};

export const getNumberHoursAndMinutes = totalSeconds => {
  if (!totalSeconds) {
    return 0;
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.ceil((totalSeconds % 3600) / 60);

  return { hours, minutes };
};

export const getHHMMSS = totalSeconds => {
  if (!totalSeconds) {
    return 0;
  }

  const hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds % 3600) / 60);
  var seconds = Math.floor(totalSeconds % 60);

  return [
    ("0" + hours).slice(-2),
    ("0" + minutes).slice(-2),
    ("0" + seconds).slice(-2)
  ].join(":");
};

export const getHHMMFromString = time => {
  if (!time) {
    return "-";
  }

  return time.substring(0, 5);
};
