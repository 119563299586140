var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "new-offer"
  }, [_c('div', {
    staticClass: "new-offer__name"
  }, [_vm._v("Добавить специальность")]), _c('el-form', {
    staticClass: "new-offer__form",
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm($event);
      }
    }
  }, [_c('div', {
    staticClass: "new-offer__data"
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.checkedSpecialities,
      callback: function callback($$v) {
        _vm.checkedSpecialities = $$v;
      },
      expression: "checkedSpecialities"
    }
  }, _vm._l(_vm.specialitiesList, function (speciality) {
    return _c('el-checkbox', {
      key: speciality.id,
      staticClass: "new-offer__checkbox",
      attrs: {
        "label": speciality.id
      }
    }, [_vm._v(" " + _vm._s(speciality.name) + " ")]);
  }), 1)], 1)]), _c('div', {
    staticClass: "new-offer__form__footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": _vm.isDisabledButton
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" Сохранить ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }