var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-block"
  }, [_c('form', {
    staticClass: "search-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmit($event);
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": _vm.placeholder
    },
    on: {
      "input": _vm.sendRequest,
      "focus": _vm.handleFocus
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('i', {
    staticClass: "search-form__icon-search",
    attrs: {
      "slot": "prefix"
    },
    slot: "prefix"
  })]), _c('transition', {
    attrs: {
      "name": "el-zoom-in-top"
    }
  }, [_vm.currentRouteName !== 'Search' && _vm.countValue > 1 && _vm.openSearchArea ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "search-form__results"
  }, [_vm.searchResults.length ? _c('div', {
    staticClass: "search-form-dropdown"
  }, [_c('el-scrollbar', {
    attrs: {
      "wrap-class": "search-form-dropdown__wrap",
      "view-class": "search-form-dropdown__view",
      "native": false
    }
  }, [_c('div', {
    staticClass: "search-form-dropdown__list"
  }, _vm._l(_vm.searchResults, function (item, index) {
    return _c('a', {
      key: index,
      staticClass: "search-form-dropdown__item",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.goToResource($event, item.tablename, item.id);
        }
      }
    }, [_c('i', {
      staticClass: "search-form-dropdown__icon",
      class: {
        'search-form-dropdown__icon_users': item.tablename === 'users',
        'search-form-dropdown__icon_companies': item.tablename === 'companies',
        'search-form-dropdown__icon_projects': item.tablename === 'projects'
      }
    }), item.name ? _c('span', {
      staticClass: "search-form-dropdown__title"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]) : _vm._e(), item.tablename ? _c('span', {
      staticClass: "search-form-dropdown__descr"
    }, [_vm._v(" " + _vm._s(item.category) + " ")]) : _vm._e()]);
  }), 0), _vm.dataResults.total > 8 ? _c('div', {
    staticClass: "search-form-dropdown__more"
  }, [_c('el-button', {
    attrs: {
      "type": "primary el-button--stretch",
      "native-type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleSubmit($event);
      }
    }
  }, [_vm._v(" Все результаты ")])], 1) : _vm._e()])], 1) : _c('div', {
    staticClass: "search-form__results-no"
  }, [_vm._v(" Нет результатов ")])]) : _vm._e()])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }